import { Box, useOutsideClick } from '@chakra-ui/react';
import type { SampleStatus } from '@resistapp/common/types';
import { ChakraStylesConfig, GroupBase, SingleValue, type OptionProps } from 'chakra-react-select';
import { useRef, useState, type ComponentType } from 'react';
import { ResistomapSelect } from '../forms/resistomap-select';
import { ArrowIcon } from '../icons/arrow-icon';
import { theme } from '../shared/theme';

interface Props<T extends { value: string; label: string }> {
  options: T[];
  value: T | null | undefined;
  onChange: (value: SingleValue<T>) => void;
  placeholder?: string;
  styles?: ChakraStylesConfig<T, false, GroupBase<T>>;
  size?: 'sm' | 'md' | 'lg';
  menuIsOpen?: boolean;
  menuIsOpenByDefault?: boolean;
  onMenuClose?: () => void;
  children?: React.ReactNode;
  renderOption?: (option: T) => React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  Option?: ComponentType<
    OptionProps<{ value: SampleStatus; label: string; disabled: boolean }> & { value: SampleStatus }
  >;
  showArrow?: boolean;
  border?: string;
  captureMenuScroll?: boolean;
}

export function IntegratedTextSelect<T extends { value: string; label: string; disabled?: boolean }>({
  options,
  value,
  onChange,
  placeholder,
  styles,
  size = 'sm',
  menuIsOpenByDefault = false,
  menuIsOpen = false,
  onMenuClose,
  children,
  renderOption,
  className,
  isDisabled = false,
  Option,
  showArrow = true,
  border,
  captureMenuScroll = true,
}: Props<T>) {
  const blurRef = useRef(null);
  const [internalMenuIsOpen, setInternalMenuIsOpen] = useState<boolean>(menuIsOpenByDefault);

  useOutsideClick({
    ref: blurRef,
    handler: () => {
      setInternalMenuIsOpen(false);
      onMenuClose?.();
    },
  });

  const handleClick = () => {
    internalMenuIsOpen && onMenuClose?.();
    setInternalMenuIsOpen(!internalMenuIsOpen);
  };

  const components: Record<string, ComponentType<OptionProps<T, false, GroupBase<T>>>> = {
    Control: () => null,
  };
  if (Option) {
    components.Option = Option as unknown as ComponentType<OptionProps<T, false, GroupBase<T>>>;
  }

  return (
    <Box ref={blurRef} position="relative" className={className}>
      <Box
        onClick={handleClick}
        cursor="pointer"
        position="relative"
        display="inline-flex"
        alignItems="center"
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          borderBottom: border ? 'inherit' : '2px dotted',
          borderColor: theme.colors.neutral500,
          borderRadius: border ? theme.borders.radius.full : undefined,
          border,
        }}
        style={{ cursor: 'pointer' }}
        className="test_integrated-text-select"
      >
        {children}
        {showArrow && (
          <ArrowIcon
            direction={internalMenuIsOpen ? 'up' : 'down'}
            style={{
              color: theme.colors.neutral600,
              width: '8px',
              marginLeft: '4px',
            }}
          />
        )}
      </Box>
      {(internalMenuIsOpen || menuIsOpen) && !isDisabled && (
        <Box position="absolute" zIndex={1} width="100%" minWidth="150px" top="100%" left="0">
          <ResistomapSelect<T, false>
            options={options}
            value={value}
            isOptionDisabled={option => Boolean(option.disabled)}
            onChange={newValue => {
              onChange(newValue);
              setInternalMenuIsOpen(false);
            }}
            placeholder={placeholder}
            chakraStyles={{
              container: provided => ({
                ...provided,
                width: '100%',
              }),
              control: () => ({
                display: 'none',
              }),
              menu: base => ({
                ...base,
                marginTop: '4px',
                width: '100%',
              }),
              ...styles,
            }}
            size={size}
            menuIsOpen={true}
            className="stripped"
            formatOptionLabel={renderOption}
            components={components}
            isDisabled={isDisabled}
            captureMenuScroll={captureMenuScroll}
          />
        </Box>
      )}
    </Box>
  );
}
