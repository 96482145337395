import { GeneGrouping, GeneGroupsByGrouping, L2Target, L2Targets } from '@resistapp/common/assays';
import {
  AllProjectEnvironmentTypesGroup,
  ComparableEnvGroupType,
  EnvironmentTypeGroup,
} from '@resistapp/common/comparable-env-groups';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { NormalisationMode } from '@resistapp/common/types';
import { values } from 'lodash';
import { DEFAULT_GENE_GROUPING } from '../data-utils/gene-groups';

export enum QueryParams {
  GENE_GROUP_GROUPING = 'ggrouping',
  GENE_GROUPS = 'ggroups',
  ENVIRONMENT_TYPE = 'etype', // NOTE: this was extended to include comparable env groups
  ENVIRONMENTS = 'environments',
  NORMALISATION_MODE = 'normalisationMode', // Previously called absoluteMode
  START = 'start',
  END = 'end',
}

export enum OtherParams {
  COUNTRY = 'country',
  VERIFICATION_CODE = 'code',
  EXPERIMENTAL = 'experimental',
}

export function getGeneGroupingParams(searchParams: URLSearchParams, allGeneGroups: GeneGroupsByGrouping) {
  const geneGroupParam = searchParams.get(QueryParams.GENE_GROUP_GROUPING);

  return isGeneGrouping(allGeneGroups, geneGroupParam) ? geneGroupParam : DEFAULT_GENE_GROUPING;
}

export function getGeneGroupsParams(searchParams: URLSearchParams | string | null) {
  const geneGroupsParam = typeof searchParams === 'string' ? searchParams : searchParams?.get(QueryParams.GENE_GROUPS);
  const plainGeneGroupsParam = splitQueryParamArray(geneGroupsParam, true) ?? [];
  // Backwards compatibility
  return plainGeneGroupsParam.filter(g => !!g).map(translateLegacyTarget);
}

export function getEnvironmentTypeParam(searchParams: URLSearchParams): EnvironmentTypeGroup | undefined {
  const plainParam = searchParams.get(QueryParams.ENVIRONMENT_TYPE);
  // Backwards compatibility for old env type strings
  const environmentTypesParam =
    plainParam === 'Waste water' || plainParam === 'Waste+water' || plainParam === 'Waste%20water'
      ? EnvironmentType.WASTEWATER
      : plainParam === 'Surface water' ||
          plainParam === 'Surface+water' ||
          plainParam === 'Surface%20water' ||
          plainParam === 'NATURAL_WATER'
        ? EnvironmentType.SURFACE_WATER
        : plainParam?.toUpperCase(); // 'Soil' etc

  if (!environmentTypesParam) {
    return undefined;
  }

  const environmentTypes = splitQueryParamArray(environmentTypesParam) as EnvironmentTypeGroup[];
  const validEnvironmentTypes = environmentTypes.filter(e => isComparableGroupOrEnvironmentType(e));

  if (validEnvironmentTypes.length > 1) {
    console.error(
      'Unexpectedly got multiple environment types in query params, reverting to one',
      validEnvironmentTypes,
    );
  }

  return validEnvironmentTypes[0] || undefined;
}

export function getEnvironmentsParams(searchParams: URLSearchParams | string | null, envNamesHaveComma: boolean) {
  const envsParamText = typeof searchParams === 'string' ? searchParams : searchParams?.get(QueryParams.ENVIRONMENTS);
  const environmentsParam = splitQueryParamArray(envsParamText, !envNamesHaveComma) ?? [];
  return environmentsParam.filter(g => !!g);
}

export function getNormalisationModeParam(searchParams: URLSearchParams): NormalisationMode {
  let mutatingNormalisationModeParam = searchParams.get(QueryParams.NORMALISATION_MODE);
  if (!mutatingNormalisationModeParam) {
    const legacyName = 'absoluteMode';
    const legacyAbsoluteModeParam = searchParams.get(legacyName);
    // Convert legacy boolean to new mode
    const newMode =
      legacyAbsoluteModeParam === 'true' ? NormalisationMode.TEN_UL_DILUTED_DNA : NormalisationMode.SIXTEEN_S;
    searchParams.set(QueryParams.NORMALISATION_MODE, newMode);
    mutatingNormalisationModeParam = newMode;
    searchParams.delete(legacyName);
  }

  // Validate that the mode is a valid NormalisationMode
  return Object.values(NormalisationMode).includes(mutatingNormalisationModeParam as NormalisationMode)
    ? (mutatingNormalisationModeParam as NormalisationMode)
    : NormalisationMode.SIXTEEN_S;
}

function isGeneGrouping(allGeneGroups: GeneGroupsByGrouping, param?: string | null): param is GeneGrouping {
  return Boolean(param && param in allGeneGroups);
}

function isComparableGroupOrEnvironmentType(param?: string | null): param is EnvironmentTypeGroup {
  return Boolean(
    param &&
      (values(EnvironmentType as Record<string, string>).includes(param) ||
        values(ComparableEnvGroupType as Record<string, string>).includes(param)),
  );
}

export function mutateEnvironmentRelatedSearchParams(
  searchParams: URLSearchParams,
  group: EnvironmentTypeGroup,
  clearEnvs: boolean,
) {
  if (clearEnvs) {
    searchParams.delete(QueryParams.ENVIRONMENTS);
  }
  if (group === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS) {
    searchParams.delete(QueryParams.ENVIRONMENT_TYPE);
  } else {
    // Note: we supported selecting multiple env types briefly in the past,
    // but to the best of my recollection the functionality was never really used
    const joinedTypes = joinQueryParamArray([group]);
    searchParams.set(QueryParams.ENVIRONMENT_TYPE, joinedTypes);
  }
}

export function mutateSearchParamsWithSelection(
  paramID: string,
  searchParams: URLSearchParams,
  wholeGroup: string[],
  next: string[],
  only = false,
) {
  // If the user wants to select only one value, we remove the old selections
  if (only) {
    searchParams.delete(paramID);
  }

  const filteredList = next.filter(n => wholeGroup.includes(n));
  // If the next group is the same as all groups, we don't add ALL the groups to the
  // URL, but instead empty URL === default to all groups selected
  const selectedEnvironments =
    JSON.stringify(next) !== JSON.stringify(wholeGroup) ? joinQueryParamArray(filteredList) : '';

  if (!selectedEnvironments) {
    searchParams.delete(paramID);
  } else {
    searchParams.set(paramID, selectedEnvironments);
  }
}

// Note: changing this will break old URLs
// Split query param arrays with a human readable string unlikely to be found (though not forbidden) in env names
export const queryParamArraySeparator = '_-.-_';
export function splitQueryParamArray(param?: string | null, allowLegacyCommaSeparator?: boolean) {
  if (param && allowLegacyCommaSeparator && !param.includes(queryParamArraySeparator) && param.includes(',')) {
    return param.split(',');
  }
  return param?.split(queryParamArraySeparator);
}

export function joinQueryParamArray(param: string[]) {
  return param.join(queryParamArraySeparator);
}

export function translateLegacyTarget(target: string): L2Target {
  switch (target) {
    case 'Beta Lactam':
    case 'Beta+Lactam':
    case 'Beta%20Lactam':
    case 'Beta-Lactam':
    case 'BETA_LACTAM':
      return L2Targets.BETA_LACTAM;

    case 'MGE':
      return L2Targets.MGE;

    case 'Integrons':
    case 'INTEGRONS':
      return L2Targets.INTEGRONS;

    case 'Vancomycin':
    case 'VANCOMYCIN':
      return L2Targets.VANCOMYCIN;

    case 'MDR':
      return L2Targets.MDR;

    case 'Trimethoprim':
    case 'TRIMETHOPRIM':
      return L2Targets.TRIMETHOPRIM;

    case 'Phenicol':
    case 'PHENICOL':
      return L2Targets.PHENICOL;

    case 'Quinolone':
    case 'QUINOLONE':
      return L2Targets.QUINOLONE;

    case 'Sulfonamide':
    case 'SULFONAMIDE':
      return L2Targets.SULFONAMIDE;

    case 'Tetracycline':
    case 'TETRACYCLINE':
      return L2Targets.TETRACYCLINE;

    case 'Aminoglycoside':
    case 'AMINOGLYCOSIDE':
      return L2Targets.AMINOGLYCOSIDE;

    case 'MLSB':
      return L2Targets.MLSB;

    case 'Other':
    case 'OTHER_MICROBIAL_MARKER':
      return L2Targets.OTHER_MICROBIAL_MARKER;

    case 'Taxonomic':
    case 'OTHER_TAXONOMIC_MARKER':
      return L2Targets.OTHER_TAXONOMIC_MARKER;

    case '16S rRNA':
    case 'SIXTEEN_S_RRNA':
      return L2Targets.SIXTEENS_RRNA;

    default:
      return target as L2Target;
  }
}

export function getCountryParam(searchParams: URLSearchParams) {
  return searchParams.get(OtherParams.COUNTRY);
}
