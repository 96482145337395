import { useUrlContext } from '@resistapp/client/contexts/url-provider';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { useProjects } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { Feature, hasFeature } from '@resistapp/common/features';
import { friendlySampleStatus } from '@resistapp/common/friendly';
import { Project } from '@resistapp/common/types';
import { useCallback, useMemo } from 'react';
import { ResistomapSelect } from '../forms/resistomap-select';
import { dotStylesForSelect } from '../shared/general-components';
import { theme } from '../shared/theme';

interface Props {
  showStatus?: boolean;
  disableNavigation?: boolean;
  onProjectSelect?: (projectId: number, project: Project) => void;
  value?: number;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  height?: string;
  menuHeight?: string;
}

export const ProjectSelector = ({
  showStatus,
  disableNavigation,
  onProjectSelect,
  value,
  maxWidth,
  maxHeight,
  minWidth,
  height,
  menuHeight,
}: Props) => {
  const navigate = useNavigateWithQuery();
  const { data: projects } = useProjects();
  const { page, projectId } = useUrlContext();
  const { user, isAdmin: isAdminUser } = useUser();

  const pageCurrent = page.current;
  const pageViable = page.viable;

  // This should redirect user so that:
  // - When they are on the pages that support projectId, they stay on the same page, but project changes
  // - When they are on the pages that don't support projectId, they are redirected to the correct page
  //    - In the priority order, if user has access to the pages: index-page -> risk-page -> reduction-page
  //    - If user doesn't have access to any of the pages, they are redirected to the research page
  const handleSelect = useCallback(
    (projectIdLocal: number, project: Project) => {
      if (disableNavigation && onProjectSelect) {
        onProjectSelect(projectIdLocal, project);
        return;
      }

      if (pageCurrent === 'research' || pageCurrent === 'admin') {
        navigate(`/research/${projectIdLocal}`, false);
      } else if (
        (pageCurrent === 'index' && hasFeature(user, Feature.ARGI)) ||
        (pageCurrent === 'risk' && hasFeature(user, Feature.RISK)) ||
        (pageCurrent === 'reduction' && hasFeature(user, Feature.REDUCTION))
      ) {
        // TODO: Use navigate
        window.location.href = `/${pageCurrent}/${projectIdLocal}`;
      } else if (
        hasFeature(user, Feature.ARGI) ||
        hasFeature(user, Feature.RISK) ||
        hasFeature(user, Feature.REDUCTION)
      ) {
        // TODO: Use navigate
        window.location.href = `/${pageViable}/${projectIdLocal}`;
      } else {
        // TODO: Use navigate
        window.location.href = `/research/${projectIdLocal}`;
      }
    },
    [navigate, pageCurrent, pageViable, user, disableNavigation, onProjectSelect],
  );

  const options = useMemo(
    () =>
      projects
        ?.map(project => ({
          label: project.name,
          value: project.id,
          searchableLabel: `${project.id} ${project.name} ${new Date(project.createdAt).toLocaleDateString()}`,
          project,
        }))
        .reverse() || [],
    [projects],
  );

  const projectIdValue = projectId.current;
  const defaultValue = useMemo(() => {
    if (value !== undefined) {
      return options.find(projectLocal => projectLocal.value === value);
    }
    return options.find(projectLocal => projectLocal.value === projectIdValue);
  }, [options, value, projectIdValue]);

  return (
    <ResistomapSelect<(typeof options)[number], false>
      options={options}
      onChange={option => {
        option && handleSelect(option.value, option.project);
      }}
      value={defaultValue}
      filterOption={(option, inputValue) => {
        const searchValue = inputValue.toLowerCase();
        return option.data.searchableLabel.toLowerCase().includes(searchValue);
      }}
      formatOptionLabel={option => option.label}
      chakraStyles={{
        container: styles => {
          return {
            ...styles,
            // width: minWidth && maxWidth ? 'max-content' : '100%',
            width: minWidth ? 'auto' : '100%', // Default to 100% width if no minWidth is specified
            maxWidth: maxWidth || '100%',
            minWidth: minWidth || '100%',
            maxHeight: maxHeight || 'auto',
            fontWeight: theme.fontWeight.light,
            fontSize: theme.fontSize.medium,
            color: theme.colors.neutral700,
          };
        },
        control: styles => {
          return {
            ...styles,
            border: theme.borders.normal,
            borderRadius: theme.borders.radius.full,
            height: height || 'auto',
            minHeight: height || styles.minHeight,
          };
        },
        valueContainer: styles => {
          return {
            ...styles,
            height: height || styles.height,
          };
        },
        menu: styles => {
          return {
            ...styles,
            maxHeight: menuHeight || styles.maxHeight,
          };
        },
        menuList: styles => {
          return {
            ...styles,
            maxHeight: menuHeight || styles.maxHeight,
          };
        },
        option: (styles, { data }) => {
          if (!showStatus) {
            return {
              ...styles,
            };
          }

          return {
            ...styles,
            ...dotStylesForSelect(
              isAdminUser ? friendlySampleStatus(data.project.type) : '-',
              data.project.userAccessCount,
            ),
            position: 'relative',
            whiteSpace: 'nowrap',
          };
        },
      }}
    />
  );
};
