import { Box } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import {
  AllProjectEnvironmentTypesGroup,
  ComparableEnvGroupType,
  EnvGroup,
  EnvironmentTypeGroup,
  comparableGroupSpecs,
  getFriendlyComparableEnvGroupName,
} from '@resistapp/common/comparable-env-groups';
import { MetricMode } from '@resistapp/common/types';
import { IntegratedTextSelect } from '../selects/integrated-text-select';
import { theme } from '../shared/theme';
import { OptionForEnvironmentType } from './types';

interface Props {
  onChange: (value: OptionForEnvironmentType['value'] | undefined) => void;
  active: boolean;
  setActive: (value: boolean) => void;
}

export function EnvironmentTypeSelector({ onChange, active, setActive }: Props) {
  const { availableEnvGroups, selectedEnvironmentTypeGroup, metricMode, selectedSiteDatum } = useOverviewContext();

  if (!availableEnvGroups) return null;

  const groupsWithAllSamplesFirst: EnvGroup[] =
    availableEnvGroups.length > 1
      ? [
          availableEnvGroups[availableEnvGroups.length - 1],
          ...availableEnvGroups.slice(0, availableEnvGroups.length - 1),
        ]
      : availableEnvGroups;

  const options: OptionForEnvironmentType[] = groupsWithAllSamplesFirst.map(({ type }) => {
    return {
      value: type,
      label: getFriendlyComparableEnvGroupName(type),
      disabled: isSelectionDisabled(type, metricMode, selectedSiteDatum),
    };
  });

  // Find the selected option or use the first available option
  const value =
    options.length > 0
      ? options.find(option => option.value === selectedEnvironmentTypeGroup) || options[0]
      : undefined;

  return (
    <Box
      onClick={() => {
        !active && setActive(true);
      }}
      style={{ cursor: 'pointer' }}
    >
      <IntegratedTextSelect<OptionForEnvironmentType>
        className="test_environment-type-selector"
        options={options}
        value={value}
        size="sm"
        menuIsOpen={active}
        onChange={selectedOption => {
          onChange(selectedOption?.value);
          setActive(false);
        }}
        onMenuClose={() => {
          setActive(false);
        }}
        renderOption={option => <div>{option.label}</div>}
      >
        <div
          style={{
            fontWeight: theme.fontWeight.bold,
          }}
        >
          {value?.label || 'Select environment type'}
        </div>
      </IntegratedTextSelect>
    </Box>
  );
}

function isSelectionDisabled(
  type: EnvironmentTypeGroup,
  metricMode: MetricMode,
  selectedSiteDatum: OverviewDatum | undefined,
) {
  const reductionModeAndSiteWithoutProcessData =
    metricMode === MetricMode.REDUCTION &&
    !!selectedSiteDatum &&
    (!selectedSiteDatum.environmentAfter ||
      !comparableGroupSpecs[ComparableEnvGroupType.WATER_TREATMENT].envFilterer(selectedSiteDatum.environmentAfter) ||
      !comparableGroupSpecs[ComparableEnvGroupType.WATER_TREATMENT].envFilterer(selectedSiteDatum.environment));
  const otherThanAllSamples = type !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;
  const waterTreatmentIffReduction =
    (metricMode === MetricMode.REDUCTION) !== (type === ComparableEnvGroupType.WATER_TREATMENT);
  const siteWithoutDataForType =
    !!selectedSiteDatum &&
    !comparableGroupSpecs[type].envFilterer(selectedSiteDatum.environment) &&
    (!selectedSiteDatum.environmentAfter ||
      !comparableGroupSpecs[type].envFilterer(selectedSiteDatum.environmentAfter));
  return (
    reductionModeAndSiteWithoutProcessData || // Disable everything in reduction mode on sites without process data
    (otherThanAllSamples && // Otherwise always enable 'All samples'
      (waterTreatmentIffReduction || // Otherwise disable water treatment for risk&argi (and all others for reduction)
        siteWithoutDataForType)) // Disable selections that have no available data on site view
  );
}
