import { getUserProjectIds } from '@resistapp/common/access';
import { Feature, hasFeature } from '@resistapp/common/features';
import { demoProjectId } from '@resistapp/common/utils';
import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { PathParams, usePathParam } from '../hooks/use-path-params';
import { useUrlLengthWarning } from '../hooks/use-url-length-warning';
import { useUser } from './use-user-context';

type UrlContextType = {
  projectId: {
    current: number | undefined;
    viable: number | undefined;
  };
  page: {
    current: string;
    viable: string;
  };
  query: string;
};

const UrlContext = createContext<UrlContextType | undefined>(undefined);

export const UrlProvider = ({ children }: { children: React.ReactNode }) => {
  const projectId = usePathParam(PathParams.projectId);
  const location = useLocation();
  const { user } = useUser();

  // Monitor URL length and warn if it gets too long
  useUrlLengthWarning();

  const accessibleProjectIds = getUserProjectIds(user);
  const latestProjectId = accessibleProjectIds[0];
  const viablePage = hasFeature(user, Feature.ARGI)
    ? 'index'
    : hasFeature(user, Feature.RISK)
      ? 'risk'
      : hasFeature(user, Feature.REDUCTION)
        ? 'reduction'
        : 'research';
  const currentPage = location.pathname.split('/')[1];

  return (
    <UrlContext.Provider
      value={{
        projectId: {
          current: projectId ? Number(projectId) : undefined,
          viable: (projectId && Number(projectId)) || (accessibleProjectIds.length ? latestProjectId : demoProjectId),
        },
        page: {
          current: currentPage,
          viable: viablePage,
        },
        query: location.search,
      }}
    >
      {children}
    </UrlContext.Provider>
  );
};

export const useUrlContext = () => {
  const context = useContext(UrlContext);

  if (!context) {
    throw new Error('useUrlContext must be used within a UrlProvider');
  }

  return context;
};
