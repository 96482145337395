import { getEnvColorById } from '@resistapp/client/components/shared/palettes';
import { AbunanceSelection, Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { buildSortIdxByGene } from '@resistapp/client/views/research-view/project-csv';
import { AbundanceStats } from '@resistapp/common/api-types';
import { GeneGrouping, GetGroup, L2Target, L2Targets, sixteenS } from '@resistapp/common/assays';
import { getBioRepGroupKey, getSimpleBioNumber } from '@resistapp/common/environments';
import { getAbundanceLog10Stats } from '@resistapp/common/statistics/abundance-stats';
import {
  Abundance,
  Environment,
  FullAbundance,
  FullProject,
  FullSample,
  FullSamplesByUID,
  NormalisationMode,
  ReplicatedSamples,
} from '@resistapp/common/types';
import {
  TRACES_VAL,
  flattenRelevantAbundances,
  flattenSamplesByUID,
  getSampleSortValue,
} from '@resistapp/common/utils';
import { ascending, sum } from 'd3-array';
import { startOfDay, startOfHour, startOfMonth, startOfYear } from 'date-fns';
import { Dictionary, compact, flatten, get, groupBy, keyBy, mapValues, sortBy, uniq, uniqBy, values } from 'lodash';
import { getNormalisedValue } from '../../../common/normalisation-mode';
import { ExperimentalMetrics } from './build-experimental-metrics';

export type BarOrBoxPlotData = BoxPlotData | BarPlotData;

export type SingleBoxPlotSeries = BoxDatum[];
export type SingleBarPlotSeries = BarDatum[];

export type BoxPlotData = SingleBoxPlotSeries[];
export type BarPlotData = SingleBarPlotSeries[];

export interface DateDatum {
  date: string;
  label: string;
}

export interface PlotDatum extends DateDatum {
  color: string;
  sampleNumber: string;
  sampleId: string;
  sortIndex: number;
}

export interface BoxDatum extends PlotDatum, AbundanceStats {
  experimentalMetrics?: ExperimentalMetrics;
}

export interface BarDatum extends PlotDatum, Dictionary<string | number> {}

export interface ResearchPlotData {
  againstTime: boolean;
  grouping: GeneGrouping;
  geneGroups: string[];
  numAnalyzed: number;
  normalisationMode: NormalisationMode;
  boxData?: BoxPlotData; // array of box series (ie. groups)
  barData?: BarPlotData; // array of bar series (ie. groups) of dicts keyd by date and targets / genes
  heatData?: BarPlotData; // array of bar series (grouped, coinciding and bio samples smashed together) of dicts keyd by date and targets / genes
  sortIdxByGene: Dictionary<number>;
}

export type PartialFilters = Pick<
  Filters,
  'selectedTargets' | 'selectedTargetGrouping' | 'normalisationMode' | 'abundances' | 'selectedEnvironmentNamesOrdered'
>;

export function buildResearchPlotData(
  project: FullProject,
  options: PartialFilters,
  allGeneGroups: Record<string, Array<L2Targets | string>>,
  getGroup: GetGroup,
): ResearchPlotData | null {
  // Note: filtering is already applied
  const maybeFocusedByUID = project.focusedByUID || project.samplesByUID;
  const {
    selectedTargets,
    selectedTargetGrouping,
    normalisationMode,
    abundances: abundanceSelection,
    selectedEnvironmentNamesOrdered,
  } = options;

  // Assume all samples have time and pick one time for replicated samples
  const { againstTime, dateByUID } = getAgainstTime(maybeFocusedByUID);
  if (againstTime === undefined) {
    // Samples without time not supported
    return null;
  }

  const envColorById = getEnvColorById(project);
  const allSamples = flattenSamplesByUID(project.samplesByUID);
  if (!allSamples.length) {
    // Legacy projects
    return null;
  }
  const allRelAbundances = flattenRelevantAbundances(allSamples, selectedTargetGrouping === sixteenS);
  const relevantRelAbundances = allRelAbundances.filter(datum => getGroup(datum.assay, selectedTargetGrouping));
  const unsortedGeneGroups = uniq(relevantRelAbundances.map(datum => getGroup(datum.assay, selectedTargetGrouping)));
  const geneGroups = allGeneGroups[selectedTargetGrouping]
    .filter(g => unsortedGeneGroups.includes(g as L2Target))
    .reverse();
  const enabledGeneGroups = geneGroups.filter(t => selectedTargets.includes(t as L2Target));
  const numAnalyzed = uniqBy(relevantRelAbundances, datum => datum.assay).filter(datum =>
    enabledGeneGroups.includes(getGroup(datum.assay, selectedTargetGrouping) as string),
  ).length;

  const maybeFocusedSamples = flattenSamplesByUID(maybeFocusedByUID);
  const sortIdxByGene = buildSortIdxByGene(allSamples, selectedTargetGrouping, getGroup, maybeFocusedSamples);
  const showTraces = abundanceSelection !== AbunanceSelection.QUANTIFIED_ONLY;
  if (againstTime) {
    // Plot against time iff some environment is sampled (non-replicatedly) on different times.
    // Plotting code assumes that box groups are from the same time point.

    const groupedByTime = groupBy(maybeFocusedSamples, sample => dateByUID[getBioRepGroupKey(sample)]);
    const data = Object.values(groupedByTime).map(timeSamples => {
      const { boxData, barData, heatData } = getBarAndBoxDataForCoincidingSamplesByEnv(
        timeSamples,
        normalisationMode,
        dateByUID as Dictionary<string>,
        selectedTargetGrouping,
        geneGroups,
        showTraces,
        envColorById,
        selectedEnvironmentNamesOrdered,
        project.id,
        getGroup,
      );
      return {
        boxData,
        barData,
        heatData: flatten(heatData),
      };
    });
    return {
      againstTime,
      geneGroups,
      numAnalyzed,
      grouping: selectedTargetGrouping,
      normalisationMode,
      boxData: data.map(d => d.boxData),
      barData: data.map(d => d.barData),
      heatData: data.map(d => d.heatData),
      sortIdxByGene,
    };
  } else {
    // Plot ordinally iff every env has exactly one sample time
    const data = getBarAndBoxDataForCoincidingSamplesByEnv(
      maybeFocusedSamples,
      normalisationMode,
      dateByUID as Dictionary<string>,
      selectedTargetGrouping,
      geneGroups,
      showTraces,
      envColorById,
      options.selectedEnvironmentNamesOrdered,
      project.id,
      getGroup,
    );
    // Sort by sortIndex for ordinal plotting
    const sortedBoxData = [...data.boxData].sort((a, b) => a.sortIndex - b.sortIndex);
    const sortedBarData = [...data.barData].sort((a, b) => a.sortIndex - b.sortIndex);
    const sortedHeatData = [...data.heatData].sort((a, b) => a[0].sortIndex - b[0].sortIndex);

    return {
      againstTime,
      geneGroups,
      numAnalyzed,
      grouping: selectedTargetGrouping,
      normalisationMode,
      boxData: sortedBoxData.map(datum => [datum]),
      barData: sortedBarData.map(datum => [datum]),
      heatData: sortedHeatData.map(datum => datum),
      sortIdxByGene,
    };
  }
}

export function getBarDatumKeys(datum: BarDatum) {
  return Object.keys(datum).filter(
    key =>
      key !== 'date' &&
      key !== 'label' &&
      key !== 'color' &&
      key !== 'sampleNumber' &&
      key !== 'sampleId' &&
      key !== 'sortIndex',
  );
  // return sortIdxByGene ? sortBy(genes, gene => sortIdxByGene[gene]) : genes;
}

export function getNumDetectedTotal(datum: BarDatum) {
  return sum(getBarDatumKeys(datum).map(key => +(datum[key] ?? 0)));
}

export function isExtraWideProject(samplesByUID: FullSamplesByUID) {
  const numSamples = Object.keys(samplesByUID).length;
  const numSamplesTreshold = 70; // 70th sample triggers fourth sample leegend column
  return numSamples >= numSamplesTreshold;
}

function shouldPlotAgainstTime(
  samplesByUID: FullSamplesByUID,
  dateByUID: Dictionary<string | undefined>,
): boolean | undefined {
  const sampleDates = Object.values(dateByUID);
  const definedSampleDates = compact(sampleDates);
  const numDefinedSampleDates = definedSampleDates.length;
  if (numDefinedSampleDates === 0 || numDefinedSampleDates !== sampleDates.length) {
    // time is not known for any or all samples
    return false;
  }
  const datesByEnvId = Object.keys(samplesByUID).reduce<Dictionary<string[]>>(
    (acc: Dictionary<string[]>, UID: string) => {
      const envId = samplesByUID[UID][0].environmentId;
      if (!get(acc, envId, undefined)) {
        acc[envId] = [];
      }
      acc[envId] = uniq([...acc[envId], dateByUID[UID] as string]);
      return acc;
    },
    {},
  );
  return Math.max(...Object.values(datesByEnvId).map(dates => dates.length)) > 1;
}

function getDateStrForReplicatedSample(samples: ReplicatedSamples): string | undefined {
  const times = compact(samples.map(sample => sample.time));
  if (!times.length || times.length !== samples.length) {
    return undefined;
  }

  if (uniq(times).length === 1) {
    return times[0].toISOString();
  } else if (uniq(times.map(t => startOfHour(t).toISOString())).length === 1) {
    return startOfHour(times[0]).toISOString();
  } else if (uniq(times.map(t => startOfDay(t).toISOString())).length === 1) {
    return startOfDay(times[0]).toISOString();
  } else if (uniq(times.map(t => startOfMonth(t).toISOString())).length === 1) {
    return startOfMonth(times[0]).toISOString();
  } else if (uniq(times.map(t => startOfYear(t).toISOString())).length === 1) {
    return startOfYear(times[0]).toISOString();
  } else {
    times.sort(ascending);
    return times[0].toISOString();
  }
}

function getBarAndBoxDataForCoincidingSamplesByEnv(
  maybeFocusedSamples: FullSample[],
  normalisationMode: NormalisationMode,
  dateByUID: Dictionary<string>,
  grouping: GeneGrouping,
  geneGroups: string[],
  showTraces: boolean,
  envColorById: Dictionary<string>,
  envOrder: string[],
  projectId: number,
  getGroup: GetGroup,
) {
  // Combine sample numbers if many exist for the same env and time.
  const groupedByEnv = groupBy(maybeFocusedSamples, sample => sample.environment.id);
  const envIds = Object.keys(groupedByEnv);
  const sortedEnvIds = sortBy(envIds, id => envOrder.indexOf(groupedByEnv[id][0].environment.name));
  return sortedEnvIds.reduce<{ boxData: BoxDatum[]; barData: BarDatum[]; heatData: BarDatum[][] }>(
    (soFar, envId) => {
      const envSamples = groupedByEnv[envId];
      const sample = envSamples[0];
      const environment = sample.environment;
      const focusedAbundances = flattenRelevantAbundances(envSamples, grouping === sixteenS); // sixteenS check is needed in case samples aren't focused yet
      const sampleUIDs = uniq(envSamples.map(sampleLocal => getBioRepGroupKey(sampleLocal)));

      const firstBioRepKey = sampleUIDs[0];
      const time = dateByUID[firstBioRepKey] && new Date(dateByUID[firstBioRepKey]).toISOString();

      return {
        boxData: [
          ...soFar.boxData,
          buildBoxDatum(
            environment,
            focusedAbundances,
            normalisationMode,
            envColorById,
            time,
            String(sample.number),
            projectId,
            sample,
            getGroup,
          ),
        ],
        barData: [
          ...soFar.barData,
          buildBarDatum(
            environment,
            grouping,
            geneGroups,
            focusedAbundances,
            time,
            envColorById,
            String(sample.number),
            projectId,
            sample,
            getGroup,
          ),
        ],
        heatData: [
          ...soFar.heatData,
          [
            ...buildSingleHeatMapSeries(
              envSamples,
              grouping,
              normalisationMode,
              showTraces,
              time,
              envColorById,
              projectId,
            ),
          ],
        ],
      };
    },
    { barData: [], boxData: [], heatData: [] },
  );
}

function buildSingleHeatMapSeries(
  samples: FullSample[],
  grouping: GeneGrouping,
  normalisationMode: NormalisationMode,
  showTraces: boolean,
  time: string,
  envColorById: Dictionary<string>,
  projectId: number,
): SingleBarPlotSeries {
  const environment = samples[0].environment;
  const sortedSamples = sortBy(samples, sample => getSimpleBioNumber(sample));
  const result = sortedSamples.map(sample => {
    const abundanceByGene = keyBy(flattenRelevantAbundances([sample], grouping === sixteenS), datum => datum.gene);
    const valueByGene = mapValues(abundanceByGene, datum =>
      showTraces && datum.traces ? TRACES_VAL : getNormalisedValue(datum, normalisationMode),
    );
    return {
      date: time,
      label: environment.name,
      color: envColorById[environment.id],
      sampleNumber: String(sample.number),
      sampleId: String(sample.id),
      sortIndex: getSampleSortValue(sample, projectId),
      ...valueByGene,
    } satisfies BarDatum;
  });
  return result;
}

function buildBarDatum(
  environment: Environment,
  grouping: GeneGrouping,
  geneGroups: string[],
  abundances: Abundance[],
  time: string,
  envColorById: Dictionary<string>,
  sampleNumber: string,
  projectId: number,
  sample: FullSample,
  getGroup: GetGroup,
): BarDatum {
  return {
    date: time,
    label: environment.name,
    color: envColorById[environment.id],
    ...geneGroups.reduce<Dictionary<string>>((acc, geneGroup) => {
      const count = uniqBy(
        abundances.filter(datum => getGroup(datum.assay, grouping) === geneGroup && !!datum.relative),
        datum => datum.gene,
      ).length;
      acc[geneGroup] = `${count}`;
      return acc;
    }, {}),
    sampleNumber,
    sampleId: String(sample.id),
    sortIndex: getSampleSortValue(sample, projectId),
  } satisfies BarDatum;
}

function buildBoxDatum(
  environment: Environment,
  focusedAbundances: FullAbundance[],
  normalisationMode: NormalisationMode,
  envColorById: Dictionary<string>,
  time: string,
  sampleNumber: string,
  projectId: number,
  sample: FullSample,
  getGroup: GetGroup,
): BoxDatum {
  const data = getAbundanceLog10Stats(
    focusedAbundances,
    {
      scope: 'detected',
      mode: normalisationMode,
      targets: values(L2Targets), // Note: research view filters (or 'focuses') abundances on a higher level in query filters
    },
    getGroup,
  );

  return {
    ...data,
    date: time,
    label: environment.name,
    color: envColorById[environment.id],
    experimentalMetrics: undefined, // buildExperimentalMetrix(focusedAbundances),
    sampleNumber,
    sampleId: String(sample.id),
    sortIndex: getSampleSortValue(sample, projectId),
  };
}

export function getAgainstTime(maybeFocusedByUID: FullSamplesByUID) {
  const dateByUID = mapValues(maybeFocusedByUID, getDateStrForReplicatedSample);
  const againstTime = shouldPlotAgainstTime(maybeFocusedByUID, dateByUID);

  return { againstTime, dateByUID };
}
