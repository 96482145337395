import { GeneGrouping, L2Target, L2Targets } from '@resistapp/common/assays';
import { FullProject } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { Dictionary, isNil } from 'lodash';
import { theme } from './theme';

export function getGroupColor(
  group: string,
  grouping: GeneGrouping,
  allGeneGroups: Record<string, Array<L2Targets | string>>,
) {
  if (group === 'All antibiotics') {
    return '#FFFFFF';
  } else if (grouping === 'l2Target' || grouping === 'antibiotic') {
    return targetPalette[group as L2Target];
  }

  const groupMembers = allGeneGroups[grouping];
  return geneGroupingPalette[groupMembers.indexOf(group) % geneGroupingPalette.length];
}

export function getGroupTextColor(
  group: string,
  grouping: GeneGrouping,
  allGeneGroups: Record<string, Array<L2Targets | string>>,
) {
  return getTextColor(getGroupColor(group, grouping, allGeneGroups));
}

export function getGroupPalette(
  groups: string[],
  grouping: GeneGrouping,
  allGeneGroups: Record<string, Array<L2Targets | string>>,
) {
  return groups.map(t => getGroupColor(t, grouping, allGeneGroups));
}

// Color map distinct sampling environments alphabetically by type and name:
export function getEnvColorById(project: FullProject): Dictionary<string> {
  const environments = sortUniqEnvironments(project.samplesByUID);
  return environments.reduce<Dictionary<string>>((acc, env, envIdx) => {
    const paletteIdx = envIdx % envPalette.length;
    acc[env.id] = env.name === 'NTC' ? '#525252' : envPalette[paletteIdx];
    return acc;
  }, {});
}

export const targetPalette: { [key in L2Target]: string } = {
  MGE: '#ffa929',
  INTEGRONS: '#fa7900',
  BETA_LACTAM: '#B71C1B',
  VANCOMYCIN: '#911B51',
  MDR: '#2e081e',
  TRIMETHOPRIM: '#4C0C69',
  PHENICOL: '#0c461b',
  QUINOLONE: '#0f9916',
  SULFONAMIDE: '#487a48',
  TETRACYCLINE: '#2a9fc6',
  AMINOGLYCOSIDE: '#2c4e81',
  MLSB: '#1a2542',
  OTHER_RESISTANCE_MARKER: '#686868',
  PATHOGEN_MARKER: '#651324',
  OTHER_TAXONOMIC_MARKER: '#b66f35',
  OTHER_MICROBIAL_MARKER: '#9467BD',
  SIXTEENS_RRNA: '#0F0F0F',
};

const miscPalette: string[] = [
  '#293651',
  '#3D68E8',
  '#872B53',
  '#8d06b0',
  '#7498E8',
  '#DC8BB1',
  '#076113',
  '#54b693',
  '#487a48',
  '#2BB151',
  '#3f5322',
  '#929290',
  '#fecf0f',
  '#c7491f',
];

export function getTextColor(
  hexBackgroundColorString: string,
  light = theme.colors.neutral100,
  dark = theme.colors.neutral900,
) {
  if (isNil(hexBackgroundColorString) || hexBackgroundColorString.length !== 7 || hexBackgroundColorString[0] !== '#') {
    console.error('Invalid hex color string:', hexBackgroundColorString, typeof hexBackgroundColorString);
    return dark; // Default to dark if invalid input
  }
  const r = parseInt(hexBackgroundColorString.substring(1, 3), 16);
  const g = parseInt(hexBackgroundColorString.substring(3, 5), 16);
  const b = parseInt(hexBackgroundColorString.substring(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000; // magic intensity numbers by gpt-4
  return brightness < 128 ? light : dark;
}
const envPalette: string[] = miscPalette;
const geneGroupingPalette: string[] = [...miscPalette].reverse();
