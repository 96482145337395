import { EnvironmentType } from '@resistapp/common/environment-types';
import {
  Dictionary,
  chain,
  chunk,
  flatten,
  groupBy,
  intersection,
  isNil,
  keys,
  mapValues,
  pickBy,
  uniq,
  uniqBy,
} from 'lodash';
import { z } from 'zod';
import { GetGroup } from './assays';
import type { EnvGroup } from './comparable-env-groups';
import { getBioRepGroupKey } from './environments';
import {
  CtsColumns,
  FullSample,
  FullSamplesByUID,
  NormalisationMode,
  PossibleZoomableLevel,
  ProjectSample,
  zEnvironment,
  type PartialDict,
  type Sample,
} from './types';

export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiamFubmVyZXNpc3RvbWFwIiwiYSI6ImNsc2xqdjNkYTBlMzEybHJua21tN3l0MmEifQ.9CxerMEg8yZP613YrwXxJg';
export const REL_ABUNDANCE_DETECTION_LIMIT = 10 ** -5;

export const TRACES_VAL = -1;

// Note: See also on-marker-click.ts if re-enabling wastpan
// export const WastPanProjectIds = [1742, 1741];
// export const WastPanProjectDescription =

//   'Antibiotic Resistance Gene Index (ARGI) in urban wastewater treatment plants in Finland. Quantification of 25 Beta Lactam resistance genes, a gene marker for fecal human pollutant and three pathogens: <i>Actinobacter baumannii,  Pseudomonas aureginosa,</i> and <i>Staphylococci</i> in raw wastewater samples collected between 2020 and 2022.';
export const oldDemoProjectIds = {
  prev2025Demo: 2176,
  rnd2024: 1962,
  rnd2020: 254,
  wastpan: 1741,
  brokenBlomminmaki: 1948,
  global: 1682,
  nepal: 1681,
  finland: 1673,
  indonesia: 1672,
  thailand: 1670,
};

export const publicProjectWoOverview = [
  2244, // DNA stablility / shelf life analysis
  2239, // Cycling protocol update
  337, // University of Aberystwyth
  332, // R&D HUS manuscript
  297, // GEUS
];

export const demoProjectId = 2385;
export const publicProjects = [demoProjectId, ...publicProjectWoOverview];

export function getEnvironmentTypeOrWasteWater(typeKeyMaybe: string | undefined | null) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (typeKeyMaybe && EnvironmentType[typeKeyMaybe as keyof typeof EnvironmentType]) || EnvironmentType.WASTEWATER;
}

export async function sleep(ms = 0) {
  return await new Promise(r => setTimeout(r, ms));
}

const sheetUrlStart = 'https://docs.google.com/spreadsheets/d/';
const sheetIdUrlEnd = '/edit';
export function parseSheetIdFromUrl(sheetLink: string | undefined) {
  const trimmedLink = sheetLink?.trim();
  if (trimmedLink && trimmedLink.indexOf(sheetUrlStart) === 0 && trimmedLink.indexOf(sheetIdUrlEnd) > 0) {
    return trimmedLink.substring(sheetUrlStart.length, trimmedLink.indexOf(sheetIdUrlEnd));
  } else {
    return undefined;
  }
}

export function getSheetUrlForId(sheetId: string) {
  return `${sheetUrlStart}${sheetId}${sheetIdUrlEnd}`;
}

export function groupBioSamples(samples: FullSample[]): FullSamplesByUID {
  return groupBy(samples, sample => getBioRepGroupKey(sample));
}

export function getAnalyzedGenes(samplesByUID: FullSamplesByUID): string[] {
  // All sampling samples should be analayzed wrt the same set of genes
  const samples = flattenSamplesByUID(samplesByUID);
  const relAbundances = flattenRelevantAbundances(samples);
  return uniq(relAbundances.map(datum => datum.gene));
}

export function flattenSamplesByUID(samplesByUID: FullSamplesByUID) {
  return flatten(Object.values(samplesByUID));
}

export function sortUniqEnvironments(samplesByUID: FullSamplesByUID) {
  // Numberical object keys are in numerical order
  return uniqBy(
    flattenSamplesByUID(samplesByUID).map(sample => ({
      ...sample.environment,
      sampleStatus: sample.status,
    })),
    env => env.id,
  );
}

export function countDetectedGenesPerSampleUID(samplesByUID: FullSamplesByUID) {
  return mapValues(
    samplesByUID,
    bioSamples =>
      uniq(
        flattenRelevantAbundances(bioSamples)
          .filter(datum => !!datum.relative)
          .map(datum => datum.gene),
      ).length,
  );
}

export function countDetectedGenesPerTarget(samples: FullSample[], getGroup: GetGroup) {
  const abundancesByTarget = groupBy(flattenRelevantAbundances(samples), r => getGroup(r.assay));
  return Object.keys(abundancesByTarget).reduce<Dictionary<number>>((acc, target) => {
    acc[target] = uniq(abundancesByTarget[target].filter(r => !!r.relative).map(r => r.gene)).length;
    return acc;
  }, {});
}

export function flattenAbundances(samples: FullSample[]) {
  return samples.flatMap(sample => sample.abundances);
}

export function flattenRelevantAbundances(samples: FullSample[], onlyAy1 = false) {
  return flattenAbundances(samples).filter(a => (onlyAy1 ? !not16S(a) : not16S(a)));
}

export function not16S(abundance: { assay: string }) {
  // TODO define based on assay info
  return abundance.assay !== 'AY1' && abundance.assay !== 'AY600' && abundance.assay !== 'AY624';
}

export type AbundanceOrAssayResult = {
  assay: string;
  relative?: number | null;
  absolute?: number | null;
  value?: number | null;
  normalisationMode?: NormalisationMode;
  copiesPerL?: number | null;
};
export function filterDetected<T extends AbundanceOrAssayResult>(
  abundances: T[],
  key: 'relative' | 'absolute' = 'relative',
): T[] {
  return abundances.filter(v => ('relative' in v && 'absolute' in v ? !isNil(v[key]) : !isNil(v.value)));
}

export const LOD = 0.00001;
export function replaceZerosWithLod(values: number[]): number[] {
  return values.map(v => (v === 0 ? LOD : v));
}

export function isStaging() {
  return getEnvironment() === 'staging';
}

export function isProduction() {
  return getEnvironment() === 'production';
}

export function getEnvironment() {
  return process.env.NODE_ENV || 'production';
}

export function isProductionLikeEnvironment() {
  return isStaging() || isProduction();
}

export function getServerUrl() {
  const env = getEnvironment();
  return env === 'production'
    ? 'https://platform.resistomap.com'
    : env === 'staging'
      ? 'https://staging.resistomap.com'
      : 'http://localhost:3001'; // replace to debug UI against prod data: https://platform.resistomap.com`
}

export function getUiUrl() {
  const env = getEnvironment();
  return env === 'production'
    ? 'https://platform.resistomap.com'
    : env === 'staging'
      ? 'https://staging.resistomap.com'
      : `http://localhost:5173`;
}

export async function chunkedAwait<A, R>(
  data: readonly A[],
  asyncFunc: (a: A, i: number) => Promise<R>,
  chunkSize = 10,
): Promise<R[]> {
  const chunks = chunk(data, chunkSize);
  const results: R[][] = [];
  for (const oneChunk of chunks) {
    const chunkResults = await Promise.all(oneChunk.map(asyncFunc));
    results.push(chunkResults);
  }
  return flatten(results);
}

export function isAllNumeric(values: Array<number | string | null>): boolean {
  return values.every(value => typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value))));
}

type MapLevelDatum = { country?: string } & Record<string, unknown>;

/*
 * Determine admin levels whose areas we can ZOOM TO. This includes
 * - First, the default zoom level: the highest level that has only one area with samples (this level is never shown as areas)
 * - Last, zoomable level that has more than one site in some area (this level is shown as colored areas, and sites are shown when zooming into one)
 * - And usefull levels in between the first and the last level
 *   - Useless levels that have as many datums as the next area are skipped so that user always gets more data on each click
 *
 * Mind the ZOOMED area & level vs SHOWN level concepts:
 * When ZOOMING into a level 2 area, data is SHOWN on level 3 (or whatever is the next available level in all samples)
 */
export type MapDataByLevel<T> = Partial<Record<PossibleZoomableLevel, T[]>> & { null?: readonly T[] };
export function determineZoomableLevels<T extends MapLevelDatum>(
  mapDataByLevel: MapDataByLevel<T> | undefined,
): PartialDict<PossibleZoomableLevel[]> {
  if (!mapDataByLevel) {
    return {};
  }
  if (!mapDataByLevel['null']) {
    throw new Error('Expected null level to exist');
  }

  if (mapDataByLevel['1'] && mapDataByLevel['1'].length > 1) {
    throw new Error('Expected maximum only one site on level 1');
  }

  const uniqueCountries = chain(mapDataByLevel['null'])
    .map(area => area.country)
    .uniq()
    .filter(country => !isNil(country))
    .value();

  const countryLevelData = uniqueCountries.reduce<PartialDict<PossibleZoomableLevel[]>>((acc, country) => {
    const countryData = chain(mapDataByLevel)
      .mapValues(levelAreas => levelAreas?.filter(area => area.country === country))
      .value();

    acc[country] = filterLevelsForCountry(countryData, country);
    return acc;
  }, {});

  return countryLevelData;
}

function filterLevelsForCountry<T extends MapLevelDatum>(
  countryData: Partial<Record<PossibleZoomableLevel | 'null', T[]>>,
  selectedCountry: string | undefined,
): PossibleZoomableLevel[] {
  const numSites = countryData['null']?.length || 0;

  return (
    chain(countryData)
      .toPairs()
      // Consider non-aggregated site level as the deepest level
      .sortBy(([level]) => (isNil(level) ? 16 : +level))
      .filter(([_, levelAreas]) => levelAreas.length > 0)
      .filter(
        // Get rid of weird corner cases where a level has less areas than its upper level
        ([_, levelAreas], pairIndex, allPairs) => !pairIndex || levelAreas.length >= allPairs[pairIndex - 1][1].length,
      )
      .filter(([adminLevel, levelAreas], pairIndex, allPairs) => {
        // Skip useless levels where the next level doesn't have any more areas (note: expects that null level still exists)
        // Exception with countries, which we handle with detecting is adminLevel 2
        return (
          (!selectedCountry && adminLevel === '2' && levelAreas.length > 1) ||
          (pairIndex < allPairs.length - 1 && allPairs[pairIndex + 1][1].length > levelAreas.length)
        );
      })
      // Ensure that some area on each level has more than one data point
      // Also drops special case null site level as the last operation
      .filter(([_, levelAreas]) => {
        return levelAreas.length < numSites;
      })
      .map(([level]) => {
        return +level as PossibleZoomableLevel;
      })
      .value()
  );
}

/*
 * Get relevant samples for overview and drop admin levels that are not present in all samples
 *
 * Overview is designed for (and may make biological sense only for) selected environment types
 */
export const zOverviewSample = z
  .object({
    environment: zEnvironment,
    time: z.date(),
  })
  .required();
export type OverviewSample = z.infer<typeof zOverviewSample>;
export function getSupportedSamplesWithConsistentAdminLevels<T extends OverviewSample>(
  samples: T[],
  envGroups?: EnvGroup[],
): T[] {
  const supportedEnvs = new Set(envGroups?.flatMap(envGroup => envGroup.envs).map(env => env.id) || []);
  const flatSupportedSamples = samples.filter(
    sample =>
      !isNil(sample.environment.inferredLat) &&
      !isNil(sample.environment.inferredLon) &&
      !isNil(sample.environment.lat) &&
      !isNil(sample.environment.lon) &&
      !isNil(sample.time) &&
      (envGroups ? supportedEnvs.has(sample.environment.id) : true),
  );
  const levelsInAllSamples = new Set(
    chain(flatSupportedSamples)
      .map(s => s.environment.adminLevels)
      .map(levels => keys(levels).map(level => +level))
      .reduce((acc, levels) => intersection(acc, levels))
      .value(),
  );
  const supportedSamplesWithConsistentLevels = flatSupportedSamples.map(sample => ({
    ...sample,
    environment: {
      ...sample.environment,
      adminLevels: pickBy(sample.environment.adminLevels, datum => levelsInAllSamples.has(datum.level)),
    },
  }));
  return supportedSamplesWithConsistentLevels;
}

export function getCountriesFromSamples(samples: Array<Pick<Sample, 'environment'>>) {
  return chain(samples)
    .map(s => s.environment.country)
    .uniq()
    .compact()
    .value();
}

export function getSampleChipIds(samples: Array<{ cts?: CtsColumns[] }>): number[] {
  return chain(samples)
    .map(s => s.cts?.map(c => c.chipId))
    .flatten()
    .compact()
    .value();
}

export function getSampleSortValue(sample: Sample, projectId: number): number {
  const projectSample = sample.projects.find(p => p.projectId === projectId);
  return projectSample ? getProjectSampleSortValue(projectSample) : 0;
}

export function getProjectSampleSortValue(projectSample: ProjectSample): number {
  return projectSample.id;
}
