import {
  Box,
  Button,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { ResistomapSelect } from '@resistapp/client/components/forms/resistomap-select';
import { DeleteIcon } from '@resistapp/client/components/icons/delete-icon';
import { NoticeIcon } from '@resistapp/client/components/icons/notice-icon';
import { ProjectSelector } from '@resistapp/client/components/project-selector/project-selector';
import { useDeleteOrganizationProject, useDeleteUserProject } from '@resistapp/client/hooks/api';
import { downloadDataUrl, getImageDataUrlFromHtml, getImageDataUrlFromSVG } from '@resistapp/client/utils/exporting';
import { resistomapOrganizationId } from '@resistapp/common/features';
import { NormalisedValueName } from '@resistapp/common/normalisation-mode';
import type {
  FullProject,
  NormalisationMode,
  Organisation,
  PartialDict,
  PossibleZoomableLevel,
  Project,
  User,
} from '@resistapp/common/types';
import { ProjectSampleAction, ProjectType } from '@resistapp/common/types';
import { GroupBase } from 'chakra-react-select';
import { chain, map } from 'lodash';

// Reanalysis of a project with an old lab sheet requires project deletion,
// and moving or copying samples will complicate deep deletion and thus re-analysis of old projects.
// (we'd probably need to manually add sample ids to the new lab sheet)
const FIRST_PROJECT_ID_WITH_NEW_LAB_SHEET = 2300;

export type LevelOption = {
  value: `${string}-${number}`;
  label: string;
  country: string;
};

export type LevelsWithZoomableAreasOption = {
  label: string; // Country name
  options: LevelOption[];
};

export const actionOptions = [
  { value: ProjectSampleAction.ADD, label: ProjectSampleAction.ADD },
  { value: ProjectSampleAction.MOVE, label: ProjectSampleAction.MOVE },
  { value: ProjectSampleAction.REMOVE, label: ProjectSampleAction.REMOVE },
] as const;

export const exportOptions = [
  { value: 1, label: 'Please choose target', id: '', type: '', plotType: '' },
  {
    value: 2,
    label: 'Detected genes chart',
    id: 'research-view-detected-genes-chart',
    type: 'chart',
    plotType: 'bar',
  },
  {
    value: 3,
    label: 'Samples chart',
    id: 'research-view-samples-chart',
    type: 'chart',
    plotType: 'box',
  },
  {
    value: 4,
    label: 'Gene details chart',
    id: 'research-view-gene-details-chart',
    type: 'chart',
    plotType: 'heat',
  },
  {
    value: 5,
    label: 'Detected genes legend',
    id: 'research-view-detected-genes-legend',
    type: 'legend',
    plotType: 'bar',
  },
  {
    value: 6,
    label: 'Samples legend',
    id: 'research-view-samples-legend',
    type: 'legend',
    plotType: 'box',
  },
  {
    value: 7,
    label: 'Gene details legend',
    id: 'research-view-gene-details-legend',
    type: 'legend',
    plotType: 'heat',
  },
] as const;

export function getZoomableLevelsOptions(
  levelsWithZoomableAreas: PartialDict<PossibleZoomableLevel[]> | undefined,
): LevelsWithZoomableAreasOption[] {
  return Object.entries(levelsWithZoomableAreas || {}).map(([country, levels]) => ({
    label: country,
    options: levels
      ? levels
          .sort((a, b) => a.valueOf() - b.valueOf())
          .map(level => ({
            value: `${country}-${level}`,
            label: `${country}: ${level}`,
            country,
          }))
      : [],
  }));
}

export function getZoomableLevelsValues(
  allLevelsWithZoomableAreas: PartialDict<PossibleZoomableLevel[]> | undefined,
): LevelOption[] {
  return Object.entries(allLevelsWithZoomableAreas || {}).reduce<LevelOption[]>((acc, [country, options]) => {
    if (!options) {
      return acc;
    }
    acc.push(
      ...options.map(level => ({
        value: `${country}-${level}` as const,
        label: `${country}: ${level}`,
        country,
      })),
    );
    return acc;
  }, []);
}

export function createOverlay(textContent: string) {
  const overlayElement = document.createElement('div');
  overlayElement.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `;

  const content = document.createElement('div');
  content.style.cssText = `    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  `;

  content.textContent = textContent;

  overlayElement.appendChild(content);
  document.body.appendChild(overlayElement);

  return overlayElement;
}

export async function onExport(
  exportObject: (typeof exportOptions)[number],
  project: FullProject,
  normalisationMode: NormalisationMode,
) {
  // The user didn't choose any type to export
  if (!exportObject.id) {
    return;
  }

  const overlay = createOverlay('Exporting the image...');
  const fileName = `${project.id}_${NormalisedValueName[normalisationMode].replace(/\s+/g, '-')}_${exportObject.label}`;

  if (exportObject.type === 'chart') {
    const padding =
      exportObject.plotType === 'heat' ? { left: 130, bottom: 60, right: 1 } : { left: 0, bottom: -10, right: 5 };
    const element = document.getElementById(exportObject.id);
    if (!(element instanceof SVGElement)) {
      throw new Error(`Element with id ${exportObject.id} is not an SVGElement`);
    }
    // When you want to preview the element on the page to check it, instead of downloading it, use keepElement: true
    try {
      const dataUrl = await getImageDataUrlFromSVG(element, exportObject.plotType, { padding, keepElement: false });
      if (dataUrl) {
        downloadDataUrl(dataUrl, `${fileName}.png`);
      }
    } catch (error) {
      console.error('Error exporting SVG:', error);
    } finally {
      document.body.removeChild(overlay);
    }
  } else {
    const element = document.getElementById(exportObject.id) as HTMLElement;
    try {
      const dataUrl = await getImageDataUrlFromHtml(element, true);
      if (dataUrl) {
        downloadDataUrl(dataUrl, `${fileName}.png`);
      }
    } catch (error) {
      console.error('Error exporting PNG:', error);
    } finally {
      document.body.removeChild(overlay);
    }
  }
}

export async function changeAdminLevels(
  levels: LevelOption[],
  patchLevels: (newLevels: Record<string, number[]>) => Promise<any>,
) {
  try {
    const newLevels = chain(levels)
      .groupBy('country')
      .mapValues(options => map(options, opt => parseInt(opt.value.split('-')[1])))
      .value();
    await patchLevels(newLevels);
  } catch (error) {
    console.error('Failed to update admin levels:', error);
    alert('Updating the administrative levels failed!');
  }
}

export interface AccessSummary {
  usersOnlyInTarget: User[];
  usersOnlyInCurrent: User[];
  orgsOnlyInTarget: Organisation[];
  orgsOnlyInCurrent: Organisation[];
}

interface AccessRightsDifferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
  accessSummary: AccessSummary;
  onProceed: () => void;
}

export function AccessRightsDifferencesModal({
  isOpen,
  onClose,
  accessSummary,
  onProceed,
}: AccessRightsDifferencesModalProps) {
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="auto" maxWidth="600px" minWidth="400px">
        <ModalHeader>
          <Flex alignItems="center" gap={2}>
            <NoticeIcon />
            <Box>Access Rights Differences</Box>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Text>There are differences in access rights between the projects:</Text>
            {accessSummary.usersOnlyInTarget.length > 0 && (
              <Box mt={2}>
                <Text fontWeight="bold">These users are in the target project, but not in the current project:</Text>
                <UnorderedList>
                  {accessSummary.usersOnlyInTarget.map(u => (
                    <ListItem key={u.id}>
                      {u.firstName} {u.lastName} ({u.email})
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
            {accessSummary.usersOnlyInCurrent.length > 0 && (
              <Box mt={2}>
                <Text fontWeight="bold">These users are in the current project, but not in the target project:</Text>
                <UnorderedList>
                  {accessSummary.usersOnlyInCurrent.map(u => (
                    <ListItem key={u.id}>
                      {u.firstName} {u.lastName} ({u.email})
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
            {accessSummary.orgsOnlyInTarget.length > 0 && (
              <Box mt={2}>
                <Text fontWeight="bold">
                  These organizations are in the target project, but not in the current project:
                </Text>
                <UnorderedList>
                  {accessSummary.orgsOnlyInTarget.map(org => (
                    <ListItem key={org.id}>
                      {org.name} {org.isDemo ? '(Demo)' : ''}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
            {accessSummary.orgsOnlyInCurrent.length > 0 && (
              <Box mt={2}>
                <Text fontWeight="bold">
                  These organizations are in the current project, but not in the target project:
                </Text>
                <UnorderedList>
                  {accessSummary.orgsOnlyInCurrent.map(org => (
                    <ListItem key={org.id}>
                      {org.name} {org.isDemo ? '(Demo)' : ''}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
          </Box>
          <Text mb={4}>Do you want to proceed with the sample changes despite these differences?</Text>
          <Flex gap={4} justifyContent="flex-end">
            <Button onClick={onClose}>Cancel</Button>
            <Button colorScheme="blue" onClick={onProceed}>
              Proceed
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

interface SampleManagementFormProps {
  project: Project;
  action: ProjectSampleAction;
  setAction: (action: ProjectSampleAction) => void;
  actionProjectId: number | undefined;
  setActionProjectId: (id: number | undefined) => void;
  pristineProjectSamples: boolean;
  setPristineProjectSamples: (pristine: boolean) => void;
  projectSamplesError: string | null;
  onManageSamples: () => void;
  isLoading: boolean;
}

export function SampleManagementForm({
  project,
  action,
  setAction,
  actionProjectId,
  setActionProjectId,
  pristineProjectSamples,
  setPristineProjectSamples,
  projectSamplesError,
  onManageSamples,
  isLoading,
}: SampleManagementFormProps) {
  return (
    <Flex flexDirection="column">
      {project.id < FIRST_PROJECT_ID_WITH_NEW_LAB_SHEET ||
        (actionProjectId && actionProjectId < FIRST_PROJECT_ID_WITH_NEW_LAB_SHEET && (
          <Box mt={2} minW="150px" maxW="350px">
            <Text fontWeight="bold" color="red">
              Moving/copying samples to/from an old project, will complicate deletion & re-analysis the source project.
            </Text>
          </Box>
        ))}
      {project.type === ProjectType.POOLED && (
        <Box mt={2} minW="150px" maxW="350px">
          <Text fontWeight="bold" color="red">
            Pooled projects not supported.
          </Text>
        </Box>
      )}
      <Flex alignItems="center" gap={2}>
        <ResistomapSelect<{ value: string; label: string }, false, GroupBase<{ value: string; label: string }>>
          options={actionOptions}
          value={{
            value: actionOptions.find(o => o.value === action)?.label as string,
            label: actionOptions.find(o => o.value === action)?.label as string,
          }}
          onChange={option => {
            if (option && 'value' in option) {
              const actionValue = option.value as ProjectSampleAction;
              setAction(actionValue);
              if (actionValue === ProjectSampleAction.REMOVE) {
                setActionProjectId(undefined);
              }
            }
            setPristineProjectSamples(false);
          }}
          onBlur={() => {
            setPristineProjectSamples(false);
          }}
          placeholder="Select action..."
          closeMenuOnSelect={true}
          styles={{
            container: provided => ({
              ...provided,
              width: '100px',
            }),
            control: provided => ({
              ...provided,
              minHeight: '32px',
              height: '32px',
            }),
            valueContainer: provided => ({
              ...provided,
              height: '32px',
              padding: '0 6px',
            }),
            input: provided => ({
              ...provided,
              margin: '0px',
            }),
          }}
        />
        <Box fontWeight="bold">Samples {action === ProjectSampleAction.REMOVE ? 'from this' : 'to'} project</Box>
      </Flex>
      <Flex>
        {action !== ProjectSampleAction.REMOVE && (
          <ProjectSelector
            disableNavigation
            onProjectSelect={projectId => {
              setActionProjectId(projectId);
              setPristineProjectSamples(false);
            }}
            value={actionProjectId}
            minWidth="200px"
            maxWidth="600px"
          />
        )}
      </Flex>
      {projectSamplesError && !pristineProjectSamples && <div style={{ color: 'red' }}>{projectSamplesError}</div>}
      <Button
        onClick={onManageSamples}
        isLoading={isLoading}
        isDisabled={Boolean(projectSamplesError || pristineProjectSamples || project.type === ProjectType.POOLED)}
      >
        <span style={{ textTransform: 'capitalize' }}>
          {actionOptions.find(o => o.value === action)?.label.toLowerCase()}{' '}
        </span>
        &nbsp;samples
      </Button>
    </Flex>
  );
}

export function UserAccessListItem({ user, project }: { user: User; project: Project }) {
  const { send } = useDeleteUserProject(user.id, project.id);
  return (
    <ListItem>
      {user.firstName} {user.lastName}{' '}
      <DeleteIcon name={`${user.firstName}'s access`} triggerDelete={async () => await send()} />
    </ListItem>
  );
}

export function OrganizationAccessListItem({
  organization,
  project,
}: {
  organization: Organisation;
  project: Project;
}) {
  const { send } = useDeleteOrganizationProject(organization.id, project.id);
  return (
    <ListItem>
      {organization.name}{' '}
      {organization.id !== resistomapOrganizationId ? (
        <DeleteIcon name={`${organization.name}'s access`} triggerDelete={async () => await send()} />
      ) : null}
    </ListItem>
  );
}

export function Warnings({ warnings }: { warnings: string[] | undefined }) {
  return (
    <div style={{ marginBottom: '16px' }}>
      {warnings?.length ? warnings.map((warning, key) => <div key={key}> {warning}</div>) : 'None'}
    </div>
  );
}

interface RemoveConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
  selectedSamplesCount: number;
}

export function RemoveConfirmationModal({
  isOpen,
  onClose,
  onProceed,
  selectedSamplesCount,
}: RemoveConfirmationModalProps) {
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="auto" maxWidth="600px" minWidth="400px">
        <ModalHeader>
          <Flex alignItems="center" gap={2}>
            <NoticeIcon />
            <Box>Confirm Sample Removal</Box>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Text>
              You are about to remove {selectedSamplesCount} sample{selectedSamplesCount !== 1 ? 's' : ''} from this
              project.
            </Text>
            <Text mt={2} fontWeight="bold" color="red.500">
              This action cannot be undone.
            </Text>
          </Box>
          <Text mb={4}>Do you want to proceed with removing these samples?</Text>
          <Flex gap={4} justifyContent="flex-end">
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="red" onClick={onProceed}>
              Remove
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
