import { Box, Button, Flex, Input, ListItem, UnorderedList } from '@chakra-ui/react';
import { ResistomapSelect } from '@resistapp/client/components/forms/resistomap-select';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { RThirdTitle } from '@resistapp/client/components/headers/r-third-title';
import { DeleteIcon } from '@resistapp/client/components/icons/delete-icon';
import { AddOrganizationAccessDropdown } from '@resistapp/client/components/selects/add-project-access-dropdown';
import { AddUserAccessDropdown } from '@resistapp/client/components/selects/add-user-access-dropdown';
import { GridView } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import {
  usePatchProjectImages,
  usePatchProjectLevels,
  usePatchProjectName,
  useProjects,
} from '@resistapp/client/hooks/api';
import { useToastWithDebounce } from '@resistapp/client/hooks/use-resistomap-toast';
import { isAdmin } from '@resistapp/common/features';
import { FullProject, ProjectSampleAction, ProjectType } from '@resistapp/common/types';
import type { GroupBase } from 'chakra-react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import {
  AccessRightsDifferencesModal,
  AccessSummary,
  exportOptions,
  getZoomableLevelsOptions,
  getZoomableLevelsValues,
  LevelOption,
  onExport,
  OrganizationAccessListItem,
  RemoveConfirmationModal,
  SampleManagementForm,
  UserAccessListItem,
  changeAdminLevels as utilsChangeAdminLevels,
  Warnings,
  type LevelsWithZoomableAreasOption,
} from './admin-section-utils';
import { getExtraFilenames } from './download-and-link-section';
import { FileDropzone } from './dropzone';

interface Props {
  project: FullProject;
  triggerDelete: (deep?: boolean) => Promise<any>;
}

export function AdminSection({ project, triggerDelete }: Props) {
  const { plotData, queryFilters, adminFeatures } = useResearchContext();
  const { refetchData: refetchProject } = useSampleDataContext();
  const { user } = useUser();
  const { data: projects } = useProjects();
  const { send: patchImages, data: imageSaved } = usePatchProjectImages(project.id);
  const patchName = usePatchProjectName(project.id, refetchProject);
  const patchLevels = usePatchProjectLevels(project.id);
  const showToast = useToastWithDebounce();

  const [projectName, setProjectName] = useState(project.name);
  const [exportObject, setExportObject] = useState<(typeof exportOptions)[number]>();
  const [levelsWithZoomableAreasOptions, setLevelsWithZoomableAreasOptions] = useState<LevelOption[]>(
    getZoomableLevelsValues(project.levelsWithZoomableAreas),
  );
  const [action, setAction] = useState<ProjectSampleAction>(ProjectSampleAction.ADD);
  const [actionProjectId, setActionProjectId] = useState<number | undefined>();
  const [pristineProjectSamples, setPristineProjectSamples] = useState(true);
  const [showAccessSummary, setShowAccessSummary] = useState<AccessSummary | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const plotFileNames = getExtraFilenames(project);

  const allLevelsWithZoomableAreasOptions: LevelsWithZoomableAreasOption[] = useMemo(
    () => getZoomableLevelsOptions(project.originalLevelsWithZoomableAreas),
    [project.originalLevelsWithZoomableAreas],
  );

  const showToastError = useCallback(
    (title: string, description: string) => {
      showToast({
        title,
        description,
        status: 'error',
        duration: 30000,
      });
    },
    [showToast],
  );

  const onDrop = useCallback(
    (files: FileWithPath[]) => {
      for (const file of files) {
        if (!file.name.endsWith('.pdf') && !file.name.endsWith('.csv')) {
          alert(`Please convert ${file.name} into pdf or csv format.`);
          return;
        }
      }
      patchImages(files);
    },
    [patchImages],
  );

  useEffect(() => {
    if (imageSaved) {
      window.location.reload();
    }
  }, [imageSaved]);

  const targetProjectId = action === ProjectSampleAction.REMOVE ? project.id : actionProjectId;
  const selectedSamples = useMemo(
    () =>
      Object.values(project.focusedByUID || {})
        .flat()
        .map(s => s.id),
    [project.focusedByUID],
  );
  const projectSamplesError = useMemo(() => {
    if (projects?.find(p => p.id === targetProjectId)?.type === ProjectType.POOLED) {
      return 'Pooled projects not supported';
    }
    if (!targetProjectId) {
      return `Please select a project to ${action.toLowerCase()} the samples ${action === ProjectSampleAction.REMOVE ? 'from' : 'to'}`;
    }
    if (!selectedSamples.length) {
      return `Please select some samples to ${action.toLowerCase()}`;
    }
    return null;
  }, [action, targetProjectId, selectedSamples, projects]);

  const changeSampleProjects = useCallback(async () => {
    try {
      await adminFeatures.patchSamplesToProject.mutateAsync({
        [`${targetProjectId}`]: {
          action,
          sampleIds: selectedSamples,
          sourceProjectId: project.id,
        },
      });
    } catch (error) {
      showToastError(
        `${action} samples ${action === ProjectSampleAction.REMOVE ? 'from' : 'to'} project failed!`,
        error instanceof Error ? error.message : 'Unknown error prevented to manage samples',
      );
      return;
    }

    showToast({
      title: `Samples (${action}) to project`,
      description: `Please notice that you might need to reload the page with default selections (e.g. if you have environments selected that were removed from the project)`,
      status: 'success',
      duration: 15000,
    });

    // Reset modal state after successful operation
    setShowAccessSummary(null);
    setIsConfirmModalOpen(false);
  }, [
    action,
    targetProjectId,
    selectedSamples,
    adminFeatures.patchSamplesToProject,
    showToastError,
    showToast,
    project.id,
  ]);

  if (!isAdmin(user)) {
    return null;
  }

  async function changeAdminLevels(levels: LevelOption[]) {
    await utilsChangeAdminLevels(levels, patchLevels.mutateAsync);
  }

  async function manageSamplesInProjects() {
    try {
      if (projectSamplesError || !targetProjectId) {
        showToast({
          title: 'Error in the form to add/move/remove samples!',
          description: projectSamplesError ?? 'Unknown error prevented to manage samples',
          status: 'error',
        });
        return;
      }

      if (action === ProjectSampleAction.REMOVE) {
        setShowRemoveConfirmation(true);
        return;
      }

      const { usersOnlyInTarget, usersOnlyInCurrent, orgsOnlyInTarget, orgsOnlyInCurrent } =
        adminFeatures.getAccessDifferences(targetProjectId);

      if (
        usersOnlyInTarget.length > 0 ||
        usersOnlyInCurrent.length > 0 ||
        orgsOnlyInTarget.length > 0 ||
        orgsOnlyInCurrent.length > 0
      ) {
        setShowAccessSummary({
          usersOnlyInTarget,
          usersOnlyInCurrent,
          orgsOnlyInTarget,
          orgsOnlyInCurrent,
        });
        setIsConfirmModalOpen(true);
        return;
      }

      // If no access differences, proceed with the patch request
      await changeSampleProjects();
    } catch (error) {
      console.error('Error in manageSamplesInProjects:', error);
      showToastError('Error managing samples', error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  }

  return (
    <GridView>
      <RSecondTitle>Project Administration</RSecondTitle>
      <Flex flexWrap="wrap" gap={oldTheme.spacing.m}>
        <Flex minWidth={250} flexDirection="column">
          <RThirdTitle>Rename project</RThirdTitle>
          <Flex alignItems="center">
            <Input
              value={projectName}
              onChange={event => {
                setProjectName(event.target.value);
              }}
            />
            <Button
              disabled={!!projectName || projectName === project.name}
              onClick={_event => {
                patchName.mutate(projectName);
              }}
            >
              Rename
            </Button>
          </Flex>
        </Flex>
        <Box minWidth={250}>
          <RThirdTitle>User access</RThirdTitle>
          <UnorderedList style={{ listStyleType: 'none' }}>
            {adminFeatures.usersWithDirectAccess.map((u, k) => (
              <UserAccessListItem key={`uali${k}`} user={u} project={project} />
            ))}
            <ListItem>
              <AddUserAccessDropdown users={adminFeatures.usersWithoutAccess} projectId={project.id} />
            </ListItem>
          </UnorderedList>
        </Box>
        <Box minWidth={250}>
          <RThirdTitle>Organization access</RThirdTitle>
          <UnorderedList style={{ listStyleType: 'none' }}>
            {adminFeatures.organizationsWithAccess.map(org => (
              <OrganizationAccessListItem key={org.id} organization={org} project={project} />
            ))}
            <ListItem>
              <AddOrganizationAccessDropdown
                organizations={adminFeatures.organizationsWithoutAccess}
                projectId={project.id}
              />
            </ListItem>
          </UnorderedList>
        </Box>
        <Box minWidth={150} maxWidth={200}>
          <RThirdTitle>Extra analyses</RThirdTitle>
          <ul>
            {plotFileNames.map((filename, key) => {
              return (
                <li key={key}>
                  {filename}{' '}
                  <DeleteIcon
                    // eslint-disable-next-line @typescript-eslint/require-await
                    triggerDelete={async () => {
                      patchImages([], filename);
                    }}
                    name={filename}
                  />
                </li>
              );
            })}
          </ul>
          <div style={{ marginTop: -20, width: '80%' }}>
            <FileDropzone onDrop={onDrop} />
          </div>
        </Box>
        <Box>
          <RThirdTitle>Warnings</RThirdTitle>
          <Warnings warnings={project.warnings} />
        </Box>
        <Box>
          <RThirdTitle>Danger zone</RThirdTitle>
          <DeleteIcon
            style={{ fontSize: oldTheme.fontSize.xl }}
            name={`${project.name} project`}
            triggerDelete={async (deep?: boolean) => {
              await triggerDelete(deep);
            }}
            message={`${project.name} project will be permanently deleted.

Would you like to also delete all samples and their data (cts values, environments)?
Click OK to delete samples and their data (only if they are not used in other projects).
Click Cancel to keep all samples and their data (deletion will fail if any samples would become orphaned).`}
          />
        </Box>
        <Flex flexDirection="column">
          <RThirdTitle>Export</RThirdTitle>
          <ResistomapSelect<(typeof exportOptions)[number], false>
            options={exportOptions}
            defaultValue={exportOptions[0]}
            onChange={option => {
              if (option && 'id' in option) {
                setExportObject(option);
              }
            }}
            id="export-type-selector"
            className="test_export-type-selector"
          />
          <Button
            onClick={() => {
              if (!exportObject || !exportObject.id || !plotData) {
                return;
              }

              void onExport(exportObject, project, queryFilters.filters.normalisationMode);
            }}
          >
            Export
          </Button>
        </Flex>
        <Flex flexDirection="column">
          <RThirdTitle>Administrative levels</RThirdTitle>
          <ResistomapSelect<LevelOption, true, GroupBase<LevelOption>>
            options={allLevelsWithZoomableAreasOptions}
            defaultValue={levelsWithZoomableAreasOptions}
            isMulti
            onChange={options => {
              setLevelsWithZoomableAreasOptions([...options]);
            }}
            placeholder="Select administrative levels..."
            closeMenuOnSelect={false}
          />
          <Button
            onClick={() => void changeAdminLevels(levelsWithZoomableAreasOptions)}
            isLoading={patchLevels.isPending}
          >
            Change levels
          </Button>
        </Flex>
        <SampleManagementForm
          project={project}
          action={action}
          setAction={setAction}
          actionProjectId={actionProjectId}
          setActionProjectId={setActionProjectId}
          pristineProjectSamples={pristineProjectSamples}
          setPristineProjectSamples={setPristineProjectSamples}
          projectSamplesError={projectSamplesError}
          onManageSamples={() => void manageSamplesInProjects()}
          isLoading={adminFeatures.patchSamplesToProject.isPending}
        />
      </Flex>
      {showAccessSummary && (
        <AccessRightsDifferencesModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
            setShowAccessSummary(null);
          }}
          accessSummary={showAccessSummary}
          onProceed={() => {
            void changeSampleProjects();
            setIsConfirmModalOpen(false);
          }}
        />
      )}
      <RemoveConfirmationModal
        isOpen={showRemoveConfirmation}
        onClose={() => {
          setShowRemoveConfirmation(false);
        }}
        onProceed={() => {
          void changeSampleProjects();
          setShowRemoveConfirmation(false);
        }}
        selectedSamplesCount={selectedSamples.length}
      />
    </GridView>
  );
}
