import { Flex, Grid, useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { getAgainstTime } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { FullProject } from '@resistapp/common/types';
import { useState } from 'react';
import { GroupChildContainer } from '../../containers/group-child-container';
import { SettingsIcon } from '../../icons/settings-icon';
import { UndoIcon } from '../../icons/undo-icon';
import { ChartGroupContainer } from '../../plots/containers/chart-group-container';
import { GeneLegendFilter } from '../../plots/legends/gene-legend-filter';
import { SampleAndTypeSearch } from '../../plots/legends/sample-search';
import { theme } from '../../shared/theme';
import { AbsoluteSwitch } from './absolute-switch';
import { TimeFilterSelector } from './time-filter-selector';

interface Props {
  style?: React.CSSProperties;
  project: FullProject | null;
  innerRef?: React.RefObject<HTMLDivElement>;
}

export function FilterBar(props: Props) {
  const { queryFilters, data } = useSampleDataContext();
  const screenSize = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg', lg2: 'lg', xl: 'xl' });

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const { againstTime } = data ? getAgainstTime(data.samplesByUID) : { againstTime: false };
  const showHiddenFilters = filterMenuOpen || screenSize === 'lg' || screenSize === 'xl';

  return (
    <Container
      ref={props.innerRef}
      className="test-filter-bar"
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        padding: '3px',
        paddingRight: '10px',
        paddingLeft: '10px',
        ...props.style,
        // overflowX: 'auto', We can't add this, since it will hide select elements if done
      }}
    >
      <Flex
        style={{
          width: '25px',
          height: '50px',
          left: '20px',
          marginLeft: '10px',
          marginRight: '10px',
        }}
        justifyContent="center"
        justifySelf="center"
        alignSelf="flex-start"
      >
        <SettingsIcon />
      </Flex>
      <Grid
        height="100%"
        templateColumns={{
          base: '1fr',
          md: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
          lg: `minmax(0, 208px) minmax(0, 250px) ${againstTime ? 'minmax(0, 200px)' : ''} max-content 1fr`,
        }}
        templateRows={{
          base: 'repeat(4, auto)',
          md: 'auto auto',
          lg: '50px', // Fixed height for large screens
        }}
        style={{
          gridColumnGap: '20px',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          transition: 'max-height 0.3s ease-in-out',
        }}
      >
        <FilterChildContainer
          style={{
            border: 0,
            backgroundColor: theme.colors.neutral50,
            gridColumn: 1,
            gridRow: 1,
          }}
        >
          <GeneLegendFilter queryFilters={queryFilters} />
        </FilterChildContainer>
        {(filterMenuOpen || screenSize !== 'base') && (
          <FilterChildContainer
            style={{
              border: 0,
              backgroundColor: theme.colors.neutral50,
              gridColumn: screenSize === 'base' ? 1 : 2,
              gridRow: screenSize === 'base' ? 2 : 1,
              gridColumnEnd: screenSize === 'md' ? 'span 2' : 'span 1',
            }}
          >
            {props.project && <SampleAndTypeSearch queryFilters={queryFilters} project={props.project} />}
          </FilterChildContainer>
        )}
        {againstTime && showHiddenFilters && (
          <FilterChildContainer
            style={{
              border: 0,
              backgroundColor: theme.colors.neutral50,
              width: '195px',
              marginRight: '-20px',
              gridColumn: screenSize === 'lg' || screenSize === 'xl' ? 3 : 1,
              gridRow: screenSize === 'base' ? 3 : screenSize === 'md' ? 2 : 1,
            }}
          >
            <TimeFilterSelector />
          </FilterChildContainer>
        )}
        {showHiddenFilters && (
          <FilterChildContainer
            style={{
              border: 0,
              backgroundColor: theme.colors.neutral50,
              gridColumn: screenSize === 'lg' || screenSize === 'xl' ? 4 : screenSize === 'md' ? 2 : 1,
              gridRow: screenSize === 'base' ? 4 : screenSize === 'md' ? 2 : 1,
            }}
          >
            <AbsoluteSwitch queryFilters={queryFilters} />
          </FilterChildContainer>
        )}

        {showHiddenFilters && (
          <Flex
            style={{
              border: 0,
              width: '100%',
              justifyContent: screenSize === 'base' ? 'flex-start' : 'flex-end',
              alignItems: 'center',
            }}
            pt={screenSize === 'base' ? '6px' : '0px'}
            pb={screenSize === 'base' ? '5px' : '0px'}
          >
            <FilterChildContainer
              onClick={queryFilters.resetFiltersStable}
              style={{
                width: screenSize === 'xl' ? '100px' : '40px',
                height: '40px',
                marginTop: '0px',
                marginBottom: screenSize === 'base' ? '5px' : '0px',
                marginLeft: screenSize === 'base' ? '0px' : '-10px',
              }}
            >
              <Flex
                style={{
                  gap: '10px',
                  width: '100px',
                  cursor: 'pointer',
                  alignItems: 'center',
                  paddingLeft: '12px',
                }}
              >
                <UndoIcon />
                {screenSize === 'xl' ? 'Reset' : ''}
              </Flex>
            </FilterChildContainer>
          </Flex>
        )}
      </Grid>
      {screenSize !== 'xl' && screenSize !== 'lg' && (
        <Flex
          justifyContent="center"
          justifySelf="center"
          alignSelf="flex-start"
          style={{
            width: '50px',
            height: '50px',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '-10px',
          }}
          onClick={() => {
            setFilterMenuOpen(!filterMenuOpen);
          }}
        >
          <FontAwesomeIcon icon={filterMenuOpen ? faChevronUp : faChevronDown} />
        </Flex>
      )}
    </Container>
  );
}

const FilterChildContainer = styled(GroupChildContainer)`
  height: 50px;
  margin: 0;
  padding: 0;
  align-content: center;
  background-color: white !important;
`;

const Container = styled(ChartGroupContainer)`
  box-shadow: 0px 2px 20px 0px rgba(41, 54, 81, 0.15);
`;
