import styled from '@emotion/styled';
import { OverviewMapContainer } from '@resistapp/client/components/map/overview-map';
import { OverviewFocusHeader } from '@resistapp/client/components/overview-focus-header/overview-focus-header';
import { ChartContextContainer } from '@resistapp/client/components/plots/trendchart/chart-container/chart-container';
import { ArgiChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/argi-chart';
import { GeneTrendChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/gene-trend-chart';
import { ReductionChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/reduction-chart';
import { RiskScoreChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/risk-score-chart';
import { SiteDetailBoxes } from '@resistapp/client/components/selected-map-environment/site-detail-boxes';
import { SiteDetailContainer } from '@resistapp/client/components/selected-map-environment/site-details/general-site-details';
import { DeadCenter } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { theme } from '@resistapp/client/components/shared/theme';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { MapContextProvider } from '@resistapp/client/contexts/map-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { TutorialContextProvider } from '@resistapp/client/contexts/tutorial-context';
import { useUrlContext } from '@resistapp/client/contexts/url-provider';
import {
  ChartDisplayMode,
  useChartDisplayMode,
} from '@resistapp/client/contexts/use-overview-context/chart-display-mode-context';
import { TrendChartProvider } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { AllProjectEnvironmentTypesGroup, ComparableEnvGroupType } from '@resistapp/common/comparable-env-groups';
import { Feature, hasFeature, hasMetricMode } from '@resistapp/common/features';
import { MetricMode } from '@resistapp/common/types';
import { demoProjectId, publicProjects } from '@resistapp/common/utils';
import { useMemo, useRef } from 'react';

interface MetricChartProps {
  metricMode: MetricMode;
  queryFilters: {
    filters: Pick<Filters, 'selectedTargets' | 'selectedTargetGrouping'>;
  };
}

export function OverviewView() {
  const { data } = useSampleDataContext();
  const { loading, error, metricMode, selectedEnvironmentTypeGroup } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const { assaysLoaded } = useAssayContext();
  const { user } = useUser();
  const { projectId, page } = useUrlContext();
  const trendChartContainerRef = useRef<HTMLDivElement>(null);
  const isPublicProject = useMemo(
    () => Boolean(projectId.viable && publicProjects.includes(projectId.viable)),
    [projectId.viable],
  );

  const isDemoProject = projectId.viable === demoProjectId;
  const hasAccess = useMemo(() => {
    return (
      (page.current === 'index' && (hasFeature(user, Feature.ARGI) || isDemoProject)) ||
      (page.current === 'reduction' && (hasFeature(user, Feature.REDUCTION) || isDemoProject)) ||
      (page.current === 'risk' && hasFeature(user, Feature.RISK))
    );
  }, [user, page, isDemoProject]);

  if (loading || !assaysLoaded) {
    return <LoadingView />;
  } else if (error) {
    throw error;
  } else if (!data?.id) {
    return (
      <DeadCenter>
        <div>Please select projects</div>
      </DeadCenter>
    );
  } else if (!hasAccess) {
    return (
      <DeadCenter>
        <div>Subscription required</div>
      </DeadCenter>
    );
  }

  const noDataAvailableForSite =
    metricMode === MetricMode.REDUCTION &&
    selectedEnvironmentTypeGroup !== ComparableEnvGroupType.WATER_TREATMENT &&
    selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;

  return (
    <TutorialContextProvider>
      <OverviewContainer className="test-overview-container">
        <MapContextProvider>
          <OverviewMapContainer />
          <DetailContainer>
            <OverviewFocusHeader />
            {noDataAvailableForSite ? (
              <SiteDetailContainer>
                <DeadCenter style={{ height: '60px' }}>No data available for this environment type</DeadCenter>
              </SiteDetailContainer>
            ) : (
              <SiteDetailBoxes />
            )}
            <ChartContextContainer>
              <div ref={trendChartContainerRef} id="trendchart-ref-container">
                <TrendChartProvider containerRef={trendChartContainerRef}>
                  {!hasMetricMode(user, metricMode) && isPublicProject && !isDemoProject ? (
                    <DeadCenter>
                      <div>Subscription required for Risk Score and Reduction views</div>
                    </DeadCenter>
                  ) : (
                    <MetricChart metricMode={metricMode} queryFilters={queryFilters} />
                  )}
                </TrendChartProvider>
              </div>
            </ChartContextContainer>
          </DetailContainer>
        </MapContextProvider>
      </OverviewContainer>
    </TutorialContextProvider>
  );
}

function MetricChart({ metricMode, queryFilters }: MetricChartProps) {
  const { chartDisplayMode } = useChartDisplayMode();
  if (chartDisplayMode === ChartDisplayMode.GENES) {
    return <GeneTrendChart />;
  }
  switch (metricMode) {
    case MetricMode.ARGI:
      return <ArgiChart queryFilters={queryFilters} />;
    case MetricMode.RISK:
      return <RiskScoreChart />;
    case MetricMode.REDUCTION:
      return <ReductionChart />;
    default:
      return <ArgiChart queryFilters={queryFilters} />;
  }
}

export function ReductionOrRawTreatedChart() {
  const { chartDisplayMode } = useChartDisplayMode();
  return chartDisplayMode === ChartDisplayMode.METRIC ? <ReductionChart /> : <GeneTrendChart />;
}

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.neutral50};
  overflow: hidden;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${oldTheme.spacing.l};
  padding: ${oldTheme.spacing.l};
  padding-bottom: 0;
`;
