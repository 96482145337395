import { chain, uniq, values } from 'lodash';
import {
  afterSubtypePostfixes,
  EnvironmentSubType,
  EnvironmentType,
  EnvSubTypePostFix,
  friendlyEnvironmentType,
} from './environment-types';
import { Environment, FullSample, MetricMode, ProcessMode, Sample } from './types';

export interface EnvGroup {
  type: EnvironmentTypeGroup;
  envs: Environment[];
}

export enum AllProjectEnvironmentTypesGroup {
  ALL_PROJECT_ENVIRONMENTS = 'ALL_PROJECT_ENVIRONMENTS',
}

export type EnvironmentTypeGroup = ComparableEnvGroupType | EnvironmentType | AllProjectEnvironmentTypesGroup;

// Biologically comparable environment groups of envirnoment that can be shown in overview at the same time
// NOTE: groups are not mutually exclusive
// NOTE: ALL_PROJECT_ENVIRONMENTS may be uncomparable, but it's included for backwards compatibility
interface ComparableEnvGroupSpec {
  type: EnvironmentTypeGroup;
  processMode: ProcessMode | undefined;
  envFilterer: (env: Pick<Environment, 'type' | 'subtype'>) => boolean;
}

// This is a "EnvironmentSubtypeGroup" of biologically comparable env subtypes
export enum ComparableEnvGroupType {
  COMMUNAL_WASTEWATER = 'COMMUNAL_WASTEWATER',
  CLINICAL_WASTEWATER = 'CLINICAL_WASTEWATER',
  RAW_WASTEWATER = 'RAW_WASTEWATER',
  TREATED_WASTEWATER = 'TREATED_WASTEWATER', // Consider deprecating in favor of just WATER_TREATMENT, (see HACK in OverviewContextProvider)
  WATER_TREATMENT = 'WATER_TREATMENT', // Treatment "processes"
  // WATER_PROCESSES = 'WATER_PROCESSES', // All water processes
}
export const allEnvGroupTypes = [
  ...values(ComparableEnvGroupType), // See above
  ...values(EnvironmentType), // Should work as is
  ...values(AllProjectEnvironmentTypesGroup), // Already excluded
];

export function getFriendlyComparableEnvGroupName(type: EnvironmentTypeGroup): string {
  switch (type) {
    case ComparableEnvGroupType.COMMUNAL_WASTEWATER:
      return 'Communal wastewater';
    case ComparableEnvGroupType.CLINICAL_WASTEWATER:
      return 'Clinical wastewater';
    case ComparableEnvGroupType.RAW_WASTEWATER:
      return 'Raw wastewater';
    case ComparableEnvGroupType.TREATED_WASTEWATER:
      return 'Treated wastewater';
    case ComparableEnvGroupType.WATER_TREATMENT:
      return 'Water treatment';
    case AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS:
      return 'All samples';
    default:
      return friendlyEnvironmentType(type);
  }
}

export const orderedComparableEnvGroupTypesForMetric = {
  [MetricMode.ARGI]: [
    ComparableEnvGroupType.COMMUNAL_WASTEWATER,
    ComparableEnvGroupType.CLINICAL_WASTEWATER,
    ComparableEnvGroupType.RAW_WASTEWATER,
    ComparableEnvGroupType.TREATED_WASTEWATER,
  ],
  [MetricMode.RISK]: [
    ComparableEnvGroupType.TREATED_WASTEWATER,
    ComparableEnvGroupType.RAW_WASTEWATER,
    ComparableEnvGroupType.CLINICAL_WASTEWATER,
    ComparableEnvGroupType.COMMUNAL_WASTEWATER,
  ],
  [MetricMode.REDUCTION]: [ComparableEnvGroupType.WATER_TREATMENT],
};

export function getOrderedEnvGroupTypesForMetric(metricMode: MetricMode | undefined): EnvironmentTypeGroup[] {
  switch (metricMode) {
    case MetricMode.ARGI:
      return uniq([...orderedComparableEnvGroupTypesForMetric[MetricMode.ARGI], ...allEnvGroupTypes]);
    case MetricMode.RISK:
      return uniq([...orderedComparableEnvGroupTypesForMetric[MetricMode.RISK], ...allEnvGroupTypes]);
    case MetricMode.REDUCTION:
      return uniq([...orderedComparableEnvGroupTypesForMetric[MetricMode.REDUCTION], ...allEnvGroupTypes]);
    default:
      return allEnvGroupTypes;
  }
}

// NOTE: Group specifications are ordered from specific to more general
//       Selection of available (and interesting) groups for a project relies on this
//       by only keeping only the first of each set of groupings that select identical set of environments
export const comparableGroupSpecs: Record<EnvironmentTypeGroup, ComparableEnvGroupSpec> = {
  [ComparableEnvGroupType.COMMUNAL_WASTEWATER]: {
    type: ComparableEnvGroupType.COMMUNAL_WASTEWATER,
    processMode: ProcessMode.BEFORE,
    envFilterer: env =>
      env.type === EnvironmentType.WASTEWATER &&
      !!env.subtype &&
      [
        EnvironmentSubType.URBAN_WASTEWATER_RAW,
        EnvironmentSubType.URBAN_RUNOFF,
        EnvironmentSubType.SEPTIC_TANK_RAW,
        EnvironmentSubType.BLACK_WATER,
      ].includes(env.subtype),
  },
  [ComparableEnvGroupType.CLINICAL_WASTEWATER]: {
    type: ComparableEnvGroupType.CLINICAL_WASTEWATER,
    processMode: ProcessMode.BEFORE,
    envFilterer: env =>
      env.type === EnvironmentType.WASTEWATER &&
      !!env.subtype &&
      [EnvironmentSubType.HOSPITAL_WASTEWATER_RAW, EnvironmentSubType.PRIMARY_HEALTH_CARE_RAW].includes(env.subtype),
  },
  [ComparableEnvGroupType.RAW_WASTEWATER]: {
    type: ComparableEnvGroupType.RAW_WASTEWATER,
    processMode: ProcessMode.BEFORE,
    envFilterer: env => env.type === EnvironmentType.WASTEWATER && !!env.subtype?.endsWith(EnvSubTypePostFix._RAW),
  },
  [ComparableEnvGroupType.TREATED_WASTEWATER]: {
    type: ComparableEnvGroupType.TREATED_WASTEWATER,
    processMode: ProcessMode.AFTER,
    envFilterer: env => env.type === EnvironmentType.WASTEWATER && !!env.subtype?.endsWith(EnvSubTypePostFix._TREATED),
  },
  [ComparableEnvGroupType.WATER_TREATMENT]: {
    type: ComparableEnvGroupType.WATER_TREATMENT,
    processMode: ProcessMode.DURING,
    envFilterer: env =>
      env.type === EnvironmentType.WASTEWATER &&
      values(EnvSubTypePostFix).some(suffix => env.subtype?.endsWith(suffix)),
  },
  ...(Object.fromEntries(
    values(EnvironmentType).map(type => [
      type,
      {
        type,
        envFilterer: (env: Environment) => env.type === type,
      },
    ]),
  ) as Record<EnvironmentType, ComparableEnvGroupSpec>),
  [AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS]: {
    type: AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS,
    processMode: undefined,
    envFilterer: () => true,
  },
};

export function getProcessMode(
  selectedGroupOrDefaultAll: EnvironmentTypeGroup | undefined,
  metricMode: MetricMode,
  samples: FullSample[],
): ProcessMode {
  if (!selectedGroupOrDefaultAll) {
    return ProcessMode.BEFORE; // Loading time default
  }
  const hasAfterSamples = samples.some(sample =>
    afterSubtypePostfixes.some(postfix => sample.environment.subtype?.endsWith(postfix)),
  );
  const processMode =
    comparableGroupSpecs[selectedGroupOrDefaultAll].processMode ??
    (metricMode === MetricMode.REDUCTION
      ? ProcessMode.DURING
      : metricMode === MetricMode.ARGI
        ? ProcessMode.BEFORE
        : // Here metricMode === MetricMode.RISK
          hasAfterSamples && selectedGroupOrDefaultAll !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS
          ? ProcessMode.AFTER
          : ProcessMode.BEFORE);
  return processMode;
}

export function sampleBelongsToEnvironmentGroup(sample: Sample, group: EnvironmentTypeGroup): boolean {
  return comparableGroupSpecs[group].envFilterer(sample.environment);
}

export function filterGroupEnvironments(environments: Environment[], group: EnvironmentTypeGroup): Environment[] {
  return environments.filter(comparableGroupSpecs[group].envFilterer);
}

export function getComparableEnvironmentGroups(
  environments: Environment[],
  metricMode: MetricMode | undefined,
  _keepDuplicateAll: boolean = false,
): EnvGroup[] {
  const orderedEnvGroupTypes = getOrderedEnvGroupTypesForMetric(metricMode);
  const comparableGroupsWithUniqEnvCombinations = chain(orderedEnvGroupTypes)
    .map(type => comparableGroupSpecs[type])
    .map(spec => ({ type: spec.type, envs: environments.filter(spec.envFilterer) }))
    .filter(group => group.envs.length > 0)
    .value();
  return comparableGroupsWithUniqEnvCombinations;
}
