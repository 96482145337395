import { defaultStyles, useTooltip } from '@visx/tooltip';
import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { TooltipProps } from '@visx/tooltip/lib/tooltips/Tooltip';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { RectReadOnly } from 'react-use-measure';
import { theme } from '../shared/theme';
export const defaultTooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  color: 'black',
};

export interface PlotTooltipProps {
  tooltipOpen: boolean;
  TooltipInPortal: React.FC<TooltipProps>;
  tooltipLeft: number;
  tooltipTop: number;
}

interface Props extends PlotTooltipProps {
  backgroundColor?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}

export interface TooltipStuff {
  containerBounds: RectReadOnly;
  TooltipInPortal: React.FC<PlotTooltipProps>;
}

// Global tooltip manager to ensure only one tooltip is visible at a time
type TooltipInstance = {
  id: symbol;
  hideTooltip: () => void;
};

const activeTooltips: TooltipInstance[] = [];

function registerTooltip(instance: TooltipInstance): () => void {
  activeTooltips.push(instance);
  return () => {
    const index = activeTooltips.findIndex(t => t.id === instance.id);
    if (index !== -1) {
      activeTooltips.splice(index, 1);
    }
  };
}

function hideAllTooltipsExcept(currentId: symbol): void {
  activeTooltips.forEach(instance => {
    if (instance.id !== currentId) {
      instance.hideTooltip();
    }
  });
}

export function PlotTooltip({
  tooltipOpen,
  TooltipInPortal,
  tooltipLeft,
  tooltipTop,
  backgroundColor,
  children,
  style,
}: Props) {
  if (!tooltipOpen) {
    return null;
  }
  return (
    <TooltipInPortal
      key={Math.random()} // needed for bounds to update correctly
      left={tooltipLeft}
      top={tooltipTop}
      style={{
        ...defaultTooltipStyles,
        zIndex: theme.zIndexes.tooltipZ,
        backgroundColor: backgroundColor || theme.colors.neutral50,
        ...style,
      }}
    >
      {children}
    </TooltipInPortal>
  );
}

export function usePlotTooltip<Data>(tooltipStuff: UseTooltipInPortal) {
  const { containerBounds, TooltipInPortal } = tooltipStuff;
  const {
    tooltipData,
    showTooltip,
    hideTooltip,
    updateTooltip,
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<Data | undefined>({
    tooltipOpen: false,
    tooltipData: undefined,
  });

  // Store the timeout ID for showing and hiding tooltips
  const [showTimeoutId, setShowTimeoutId] = React.useState<number | null>(null);
  const [hideTimeoutId, setHideTimeoutId] = React.useState<number | null>(null);

  // Track current tooltip identity and latest position to prevent flickering
  const currentTooltipIdRef = React.useRef<string | null>(null);
  const latestPositionRef = React.useRef<{ x: number; y: number; data: Data | undefined }>({
    x: 0,
    y: 0,
    data: undefined,
  });

  // Create a unique identifier for this tooltip instance
  const instanceIdRef = React.useRef<symbol>(Symbol('tooltip-instance'));

  // Delay in milliseconds for showing and hiding tooltips
  const showDelay = 150; // 150ms delay before showing tooltip
  const hideDelay = 100; // 100ms delay before hiding tooltip

  // Function to generate unique ID for tooltip data
  const getTooltipId = useCallback((data: unknown): string | null => {
    // Try to use common identifiers if available
    if (!data || typeof data !== 'object') return null;
    const obj = data as Record<string, unknown>;
    if (obj.barIdentifier && typeof obj.barIdentifier === 'string') {
      return `bar-${obj.barIdentifier}`;
    }
    if (obj.antibiotic && typeof obj.antibiotic === 'string') {
      return `antibiotic-${obj.antibiotic}`;
    }
    return JSON.stringify(data); // Fallback, heavier
  }, []);

  // Enhanced hideTooltip function with delay
  const hideTooltipWithDelay = useCallback(() => {
    if (showTimeoutId) {
      window.clearTimeout(showTimeoutId);
      setShowTimeoutId(null);
    }

    if (hideTimeoutId) {
      window.clearTimeout(hideTimeoutId);
    }

    const timeoutId = window.setTimeout(() => {
      hideTooltip();
      currentTooltipIdRef.current = null;
      latestPositionRef.current.data = undefined;
    }, hideDelay);

    setHideTimeoutId(timeoutId);
  }, [hideTooltip, showTimeoutId, hideTimeoutId]);

  // Immediate hide function (no delay) for use by global manager
  const hideTooltipImmediate = useCallback(() => {
    if (showTimeoutId) {
      window.clearTimeout(showTimeoutId);
      setShowTimeoutId(null);
    }

    if (hideTimeoutId) {
      window.clearTimeout(hideTimeoutId);
      setHideTimeoutId(null);
    }

    hideTooltip();
    currentTooltipIdRef.current = null;
    latestPositionRef.current.data = undefined;
  }, [hideTooltip, showTimeoutId, hideTimeoutId]);

  // Register this tooltip instance with the global manager
  useEffect(() => {
    const unregister = registerTooltip({
      id: instanceIdRef.current,
      hideTooltip: hideTooltipImmediate,
    });

    return () => {
      unregister();
      // Also ensure we clean up any timeouts
      if (showTimeoutId) window.clearTimeout(showTimeoutId);
      if (hideTimeoutId) window.clearTimeout(hideTimeoutId);
    };
  }, [hideTooltipImmediate, showTimeoutId, hideTimeoutId]);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent | React.TouchEvent, newTooltipData: Data | undefined, enter?: boolean) => {
      if (newTooltipData) {
        // Get an identity for this tooltip data
        const tooltipId = getTooltipId(newTooltipData);
        const isSameTooltipData = tooltipId && tooltipId === currentTooltipIdRef.current;

        // Clear any existing hide timeout
        if (hideTimeoutId) {
          window.clearTimeout(hideTimeoutId);
          setHideTimeoutId(null);
        }

        const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
        const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

        // Always update the latest position and data reference regardless of whether we show it now
        latestPositionRef.current = {
          x: containerX,
          y: containerY,
          data: newTooltipData,
        };

        // If we have the same tooltip data already showing, just update position
        // without hiding/showing again (prevents flicker within the same bar)
        if (tooltipOpen && isSameTooltipData) {
          updateTooltip({
            tooltipData: newTooltipData,
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipOpen: true,
          });
          return;
        }

        if (enter) {
          // Hide all other tooltips when we're about to show a new one
          hideAllTooltipsExcept(instanceIdRef.current);

          // Clear any existing show timeout
          if (showTimeoutId) {
            window.clearTimeout(showTimeoutId);
          }

          // Set a new timeout for showing the tooltip
          const timeoutId = window.setTimeout(() => {
            // Use the LATEST mouse position when the timer fires, not the original one
            const { x, y, data } = latestPositionRef.current;
            if (data) {
              showTooltip({
                tooltipLeft: x,
                tooltipTop: y,
                tooltipData: data,
              });
              currentTooltipIdRef.current = getTooltipId(data);
            }
          }, showDelay);

          setShowTimeoutId(timeoutId);
        } else {
          // Hide all other tooltips immediately when we're updating an existing one
          hideAllTooltipsExcept(instanceIdRef.current);

          updateTooltip({
            tooltipData: newTooltipData,
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipOpen: true,
          });
          currentTooltipIdRef.current = tooltipId;
        }
      } else {
        hideTooltipWithDelay();
      }
    },
    [
      containerBounds,
      showTimeoutId,
      hideTimeoutId,
      showTooltip,
      hideTooltipWithDelay,
      updateTooltip,
      tooltipOpen,
      getTooltipId,
    ],
  );

  return {
    handleMouseMove,
    tooltipData,
    hideTooltip: hideTooltipWithDelay,
    tooltipProps: {
      tooltipOpen: tooltipOpen && !!tooltipData,
      TooltipInPortal,
      tooltipLeft,
      tooltipTop,
    } satisfies PlotTooltipProps,
  };
}
