import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Stack } from '@chakra-ui/layout';
import { ResistomapButton } from '@resistapp/client/components/buttons/resistomap-button';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { FormView } from '@resistapp/client/components/shared/layout';
import { Loading } from '@resistapp/client/components/states/loading';
import { usePostUser } from '@resistapp/client/hooks/api';
import { useEffect, useState } from 'react';

export function CreateUserView() {
  const { loading, error, send, data: user } = usePostUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [projectId, setProjectId] = useState('');

  useEffect(() => {
    if (user) {
      setPassword(user.token || '');
    }
  }, [user]);

  return (
    <FormView>
      <RSecondTitle>New user</RSecondTitle>
      <Stack spacing={5} width="full">
        <p>NOTE: Creating users is currently broken!</p>
        <FormControl isRequired isDisabled={loading || !!password}>
          <FormLabel htmlFor="name">First name</FormLabel>
          <Input
            value={firstName}
            onChange={event => {
              setFirstName(event.target.value);
            }}
            id="name"
            name="name"
            placeholder="First name"
            autoFocus
            width="full"
          />
        </FormControl>
        <FormControl isRequired isDisabled={loading || !!password}>
          <FormLabel htmlFor="lastName">Last name</FormLabel>
          <Input
            value={lastName}
            onChange={event => {
              setLastName(event.target.value);
            }}
            id="lastName"
            name="lastName"
            placeholder="Last name"
            width="full"
          />
        </FormControl>
        <FormControl isRequired isDisabled={loading || !!password}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            value={email}
            onChange={event => {
              setEmail(event.target.value);
            }}
            id="email"
            name="email"
            placeholder="Email"
            width="full"
          />
        </FormControl>
        <FormControl isRequired isDisabled={loading || !!password}>
          <FormLabel htmlFor="projectId">Project #</FormLabel>
          <Input
            value={projectId}
            onChange={event => {
              setProjectId(event.target.value);
            }}
            type="number"
            id="projectId"
            name="projectId"
            placeholder="Project #"
            width="full"
          />
          {!projectId && !parseInt(projectId, 10) && (
            <FormHelperText>Copy the project number from the URL of the appropriate project</FormHelperText>
          )}
        </FormControl>
        <br />
        <ResistomapButton
          onClick={() => {
            send({ firstName, lastName, email });
          }}
          isDisabled={!!password || loading || !firstName.trim() || !lastName.trim() || !projectId}
        >
          {loading ? 'Adding...' : 'Add'}
        </ResistomapButton>

        <br />
        {error && error.message}
        {loading && <Loading />}
        {password && `User created! Password: ${password} (be sure to copy this!)`}
      </Stack>
    </FormView>
  );
}
