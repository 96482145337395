import styled from '@emotion/styled';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isDemo } from '@resistapp/common/features';
import { theme } from '../components/shared/theme';
import { useUser } from '../contexts/use-user-context';

interface Props {
  href: string;
  text: string;
  number: string;
}

export function useScienceReference(props: Props) {
  const { user } = useUser();

  // Return null components if the user is not logged in or is part of a demo organization
  if (isDemo(user)) {
    return [() => null, () => null];
  }

  return [
    () => <Reference {...props} key={`reference-${props.number}`} />,
    () => <RefNumLink {...props} key={`refnum-${props.number}`} />,
  ];
}

export const References = styled.div`
  display: flex;
  flex-direction: column;
`;

function Reference(props: Props) {
  return (
    <RefSmall href={props.href} target="_blank" rel="noreferrer">
      <RefNum {...props} /> {props.text} <SmallExternalLinkIcon icon={icon(faExternalLinkAlt)} />
    </RefSmall>
  );
}

function RefNumLink(props: Pick<Props, 'number' | 'href'>) {
  return (
    <Ref href={props.href} target="_blank" rel="noreferrer">
      <RefNum {...props} />
    </Ref>
  );
}

function RefNum(props: Pick<Props, 'number' | 'href'>) {
  return (
    <RefNumStyler>
      <sup>{props.number}</sup>
    </RefNumStyler>
  );
}

const RefStyle = `
  font-weight: 600;
  color: ${theme.colors.neutral900};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${theme.colors.neutral500};
  }
`;
const Ref = styled.a`
  ${RefStyle}
`;
const RefNumStyler = styled.span`
  ${RefStyle}
  padding: 4;
  margin: -4;
`;

export const RefSmall = styled(Ref)`
  font-size: 12px;
`;

export const SmallExternalLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${theme.oldFontSize.xxs} !important;
`;
