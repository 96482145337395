import { chain } from 'lodash';
import { MetricMode, Organisation, User } from './types';
import { demoProjectId } from './utils';

export function getUserProjectIdsThroughOrganization(user: User | undefined | null): number[] {
  const orgProjectIds = (user?.organizations || []).flatMap(org => org.accesses.map(access => access.projectId));
  const sortedIds = chain([...orgProjectIds])
    .sortBy(id => -id)
    .uniq()
    .value();

  return sortedIds;
}

export function getUserProjectIdsWithDirectAccess(user: User | undefined | null): number[] {
  const directProjectIds = (user?.directAccesses || []).map(access => access.projectId);
  const sortedIds = chain([...directProjectIds])
    .sortBy(id => -id)
    .uniq()
    .value();

  return sortedIds;
}

export function getUserProjectIds(user: User | undefined | null): number[] {
  const directProjectIds = getUserProjectIdsWithDirectAccess(user);
  const orgProjectIds = getUserProjectIdsThroughOrganization(user);

  return chain([...directProjectIds, ...orgProjectIds])
    .sortBy(id => -id)
    .concat(demoProjectId)
    .uniq()
    .value();
}

export function getUserDefaultMetric(user: User | undefined | null): MetricMode | null {
  const orgDefaults = chain(user?.organizations || [])
    .map(o => o.defaultMetric)
    .compact()
    .uniq()
    .value();
  return Object.values(MetricMode).find(mode => orgDefaults.includes(mode)) || MetricMode.ARGI;
}

export function getProjectOrganizationIds(organization: Organisation | undefined | null): number[] {
  return (organization?.accesses || []).map(access => access.projectId);
}
