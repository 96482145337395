import styled from '@emotion/styled';
import { LegendInfoIcon } from '@resistapp/client/components/info-icons/legend-info-icon';
import { MetricTitleWithDisclaimer } from '@resistapp/client/components/shared/overview-metric-title';
import { oldCss, theme } from '@resistapp/client/components/shared/theme';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import {
  ChartDisplayMode,
  ChartDisplayModeProvider,
  useChartDisplayMode,
} from '@resistapp/client/contexts/use-overview-context/chart-display-mode-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { L2Targets } from '@resistapp/common/assays';
import { capitalizeFirstLetter, friendlyL2Target } from '@resistapp/common/friendly';
import { MetricMode } from '@resistapp/common/types';
import { oldTheme } from '../../../shared/old-styles';
import { ChartDisplayToggle } from './chart-display-toggle';

interface Props {
  children: React.ReactNode;
  title?: string;
  description?: React.ReactNode;
  tooltipContent?: React.ReactNode;
}

export function ChartContextContainer({ children }: Props) {
  return (
    <ChartDisplayModeProvider>
      <ChartContainer>{children}</ChartContainer>
    </ChartDisplayModeProvider>
  );
}

function ChartContainer({ children }: Props) {
  const {
    metricMode,
    shownAdminLevel,
    selectedAntibiotic,
    selectedSiteDatum,
    zoomedMapData,
    effectiveSiteDetailsProcessMode,
    activeOverviewConfiguration,
  } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const { chartDisplayMode } = useChartDisplayMode();

  const argiTitle =
    queryFilters.filters.selectedTargetGrouping === 'assay'
      ? 'Gene Trend'
      : `${selectedAntibiotic ? friendlyL2Target(selectedAntibiotic as L2Targets) : 'Antibiotic'} Resistance Trend`;
  const title =
    metricMode === MetricMode.ARGI
      ? argiTitle
      : metricMode === MetricMode.RISK
        ? 'Comparative health risk trend'
        : 'History performance';

  const object = shownAdminLevel === null ? 'site' : 'area';
  const changesAcrossTimeForObjects = `across time${get_inRawOrEffluentSamplesString(selectedSiteDatum || zoomedMapData, effectiveSiteDetailsProcessMode)} for the selected ${object}(s)`;

  // Determine if we should use 'gene' as the metric name override based on chart display mode
  // Apply this for all metric modes
  const maybeFirstUp = (s: string) => (selectedAntibiotic ? s : capitalizeFirstLetter(s));
  const metricNameOverride =
    chartDisplayMode === ChartDisplayMode.METRIC
      ? undefined
      : queryFilters.filters.selectedTargetGrouping === 'assay'
        ? 'genes'
        : metricMode === MetricMode.REDUCTION
          ? maybeFirstUp('avg. genes')
          : maybeFirstUp('avg. ARGs per L');

  const description =
    metricMode === MetricMode.ARGI ? (
      <>
        <MetricTitleWithDisclaimer noBold metricNameOverride={metricNameOverride} /> &nbsp;
        {changesAcrossTimeForObjects}
      </>
    ) : metricMode === MetricMode.RISK ? (
      <span>
        <MetricTitleWithDisclaimer noBold metricNameOverride={metricNameOverride} /> {changesAcrossTimeForObjects}
      </span>
    ) : (
      <span>
        <MetricTitleWithDisclaimer noBold metricNameOverride={metricNameOverride} /> {changesAcrossTimeForObjects}
      </span>
    );

  return (
    <Container className="test-chart-container">
      {
        <HeaderContainer>
          <div>
            {title && <Title>{title}</Title>}
            <InLineDiv>
              {description}
              &nbsp;
              <LegendInfoIcon metricMode={metricMode} activeOverviewConfiguration={activeOverviewConfiguration} />
            </InLineDiv>
          </div>
          <ChartDisplayToggle />
        </HeaderContainer>
      }
      {children}
    </Container>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const InLineDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${oldCss.greyBorder};
  padding: ${theme.spacing[6]};
  background-color: white;
`;

const Title = styled.div`
  font-size: ${oldTheme.fontSize.l};
  font-weight: ${oldTheme.fontWeight.bold};
`;
