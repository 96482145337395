import { format, isValid } from 'date-fns';
import { isNil } from 'lodash';
import { L2Targets } from './assays';
import { ProjectType, SampleStatus } from './types';
import { TRACES_VAL } from './utils';

// TODO: This should be undefined, instead of 1900, but it seems to break something currently
export const DEFAULT_START_INTERVAL: Readonly<Date> = Object.freeze(new Date('1900'));
export const DEFAULT_END_INTERVAL: Readonly<Date> = Object.freeze(new Date('9999'));
export type StandardDateFormat =
  | Date
  | `${number}-${number}-${number}`
  | `${number}-${number}-${number}T${number}:${number}:${number}`;

export function friendlyDate(
  input: StandardDateFormat | Date,
  mondayInfo: 'none' | 'prefixMon' | 'replaceMon',
  alwaysIncludeYear = false,
) {
  const date = ensureLocalMidnight(input);
  const shortMonth = date.toLocaleString('en-US', { month: 'short' });
  const dayOfMonth = date.getDate();
  const yearPostfix = alwaysIncludeYear || (dayOfMonth === 1 && shortMonth === 'Jan') ? ` ${date.getFullYear()}` : '';
  const isMonday = mondayInfo !== 'none' && dayOfMonth === 1;
  if (mondayInfo === 'replaceMon' && isMonday) {
    return `Mon`;
  }
  const mondayPrefix = isMonday && mondayInfo === 'prefixMon' ? 'Mon. ' : '';
  return `${mondayPrefix}${dayOfMonth}. ${shortMonth}${yearPostfix}`;
}

export function friendlyMonth(
  input: StandardDateFormat | Date,
  yearInfo: 'postfixAlways' | 'postfixJan' | 'replaceJan',
) {
  const date = ensureLocalMidnight(input);
  const shortMonth = date.toLocaleString('en-US', { month: 'short' });
  const dayOfMonth = date.getDate();
  const year =
    yearInfo === 'postfixAlways' || (dayOfMonth === 1 && shortMonth === 'Jan') ? `${date.getFullYear()}` : '';

  return yearInfo === 'replaceJan' && year
    ? year
    : normalizeSpaces(`${date.toLocaleString('en-US', { month: 'short' })} ${year}`);
}

export function friendlyAbundance(value: number | undefined | null) {
  if (!value || value === TRACES_VAL) {
    return '-';
  }
  return value.toFixed(value > 1 ? 0 : 6);
}

export function ensureDate(input: string | Date | null | undefined): Date {
  return input instanceof Date ? input : new Date(input || ''); // override default behaviour and treat nils as invalid dates (by default null is treated as 1970)
}

export function ensureValidDate(input: string | Date | null | undefined): Date {
  const date = ensureDate(input);
  if (!isValid(date)) {
    throw new Error(`Invalid date: ${typeof input === 'string' ? input : input?.toISOString()}`);
  }
  return date;
}

export function ensureLocalMidnight(_input: string | Date): Date {
  const input = ensureDate(_input);
  return new Date(input.getFullYear(), input.getMonth(), input.getDate());
}

export function ensureUtcMidnight(date: Date | string): Date {
  const dateObj = ensureDate(date);
  if (!isValid(dateObj)) {
    return dateObj; // TODO fix me, this does not ensure anything
  }

  return new Date(Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate()));
}

export function ensureLocalStartOfMonth(date: StandardDateFormat | Date): Date {
  const dateObj = ensureLocalMidnight(date);
  if (!isValid(dateObj)) {
    return dateObj; // TODO fix me, this does not ensure anything
  }
  return new Date(dateObj.getFullYear(), dateObj.getMonth());
}

export function ensureLocalStartOfNextMonth(date: StandardDateFormat | Date): Date {
  const dateObj = ensureLocalMidnight(date);
  if (!isValid(dateObj)) {
    return dateObj; // TODO fix me, this does not ensure anything
  }
  return dateObj.getMonth() == 11
    ? new Date(dateObj.getFullYear() + 1, 0)
    : new Date(dateObj.getFullYear(), dateObj.getMonth() + 1);
}

export function ensureLocalValidMidnightOrDefault(
  input: StandardDateFormat | Date | null | undefined,
  defaultDate: Date,
): Date {
  const inputDate = input ? ensureLocalMidnight(input) : undefined;
  return inputDate && isValid(inputDate) ? inputDate : defaultDate;
}

export function ensureLocalPreviousMonth(input: Date): Date {
  return input.getMonth() === 0
    ? new Date(input.getFullYear() - 1, 11, input.getDate())
    : new Date(input.getFullYear(), input.getMonth() - 1, input.getDate());
}
export function getNextMonth(input: Date): Date {
  return new Date(input.getFullYear(), input.getMonth() + 1, input.getDate());
}

export function getProjectTypeInfo(type: ProjectType): string {
  switch (type) {
    case ProjectType.NORMAL:
      return 'Normal project';
    case ProjectType.POOLED:
      return 'Pooled project that has been combined from other, normal projects automatically with the Pooled project tool. Samples are marked as duplicated and excluded global statistics to avoid double counting.';
    case ProjectType.LEGACY:
      return 'Very old legacy project without results (only html heatmap). A handful of old projects have legacy type, but there should be no need to set the type to LEGACY for any more projects.';
  }
}

export function getSampleStatusInfo(status: SampleStatus): string {
  switch (status) {
    case SampleStatus.DRAFT:
      return 'New sample to be manually reviewed in the QA process. All drafts should eventually be moved to another status or be deleted. Excluded from global statistics for now.';
    case SampleStatus.APPROVED:
      return 'Reviewed and approved sample with representable data to be included in global statistics. Exactly one copy of each succesfull sample should be marked with on of the three approved statuses. Counted towards global statistics.';
    case SampleStatus.APPROVED_WITH_ISSUES:
      return 'Approved, but with suspected master mix or other issues. Select this only if the sample is not re-analysed. Counted towards total analysed samples, but not used for other global statistics.';
    case SampleStatus.APPROVED_SKIP_RESULT_STATISTICS:
      return 'Approved, non-standard (eg. research) sample that should not be counted towards global statistics. Counted towards total analysed samples, but not used for other global statistics.';
    case SampleStatus.DUPLICATE:
      return `An approved sample that is a duplicate, eg. there exists another approved sample that has the same cts values analysed. Excluded from all global statistics to avoid double counting`;
    case SampleStatus.POOLED:
      return `A duplicate sample created by the pooling tool when pooling a project. Excluded from all global statistics to avoid double counting`;
    case SampleStatus.FAILED:
      return 'Failed sample. Excluded from global statistics.';
  }
}

export function friendlyShortMonth<T>(value: T) {
  const dateValue =
    value instanceof Date ? value : typeof value === 'string' ? new Date(value) : new Date(Number(value));
  return format(dateValue, 'MMM');
}

export function capitalizeWords(str: string): string {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeOnlyWords(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function sentanceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function normalizeSpaces(str: string): string {
  return str.replace(/\s+/g, ' ');
}
export function truncateLongString(string: string) {
  return string.length > 10 ? `${string.slice(0, 10)}...` : string;
}

export function friendlyCopyNumber(value: number | null | undefined) {
  if (isNil(value)) {
    return '-';
  }

  // Show decimals when the number is low enough. So that the decimal can fit there and there
  // won't be awkward situations, where we show 1M and 1M, instead of 1M and 1.2M
  const billions = value >= 1_000_000_000 && value / 1_000_000_000;
  const millions = !billions && value >= 1_000_000 && value / 1_000_000;
  const thousands = !billions && !millions && value >= 1_000 && value / 1_000;
  const dividedValue = billions || millions || thousands;

  if (!dividedValue) {
    return value.toFixed(0);
  }

  const showDecimals =
    (billions && value < 5_000_000_000) || (millions && value < 5_000_000) || (thousands && value < 5_000);

  const withDecimal = showDecimals ? dividedValue.toFixed(1) : dividedValue.toFixed(0);
  const formatted =
    showDecimals && withDecimal[withDecimal.length - 1] === '0' ? withDecimal.slice(0, -2) : withDecimal;

  return billions ? `${formatted}B` : millions ? `${formatted}M` : thousands ? `${formatted}k` : value.toFixed(0);
}

export function friendlyFoldChange(d: number | null | undefined, fixedDecimals: number, showPlus: boolean) {
  if (isNil(d)) {
    return '-';
  }
  const plusSign = d > 0 && showPlus ? '+' : '';
  return `${plusSign}${d.toFixed(fixedDecimals)}`.replace('Infinity', '9.9');
}

export function friendlyPercentage(value: number | null | undefined) {
  if (isNil(value)) {
    return '-';
  }
  if (value > 999) {
    return '>999%';
  }
  return `${value > 0 ? '+' : ''}${value.toFixed(0)}%`;
}

export function friendlyL2Target(l2Target: L2Targets): string {
  switch (l2Target) {
    case L2Targets.BETA_LACTAM:
      return 'Beta-Lactam';
    case L2Targets.MGE:
      return 'MGE';
    case L2Targets.INTEGRONS:
      return 'Integrons';
    case L2Targets.VANCOMYCIN:
      return 'Vancomycin';
    case L2Targets.MDR:
      return 'MDR';
    case L2Targets.TRIMETHOPRIM:
      return 'Trimethoprim';
    case L2Targets.PHENICOL:
      return 'Phenicol';
    case L2Targets.QUINOLONE:
      return 'Quinolone';
    case L2Targets.SULFONAMIDE:
      return 'Sulfonamide';
    case L2Targets.TETRACYCLINE:
      return 'Tetracycline';
    case L2Targets.AMINOGLYCOSIDE:
      return 'Aminoglycoside';
    case L2Targets.MLSB:
      return 'MLSB';
    case L2Targets.OTHER_RESISTANCE_MARKER:
      return 'Other Resistance Marker';
    case L2Targets.PATHOGEN_MARKER:
      return 'Pathogen Marker';
    case L2Targets.OTHER_TAXONOMIC_MARKER:
      return 'Taxonomic Marker';
    case L2Targets.OTHER_MICROBIAL_MARKER:
      return 'Other Microbial Marker';
    case L2Targets.SIXTEENS_RRNA:
      return '16S rRNA';
  }
}

export function friendlySampleStatus(status: ProjectType | SampleStatus): string {
  return status.slice(0, 2);
}
