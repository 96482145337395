export enum LocalStorageId {
  cookiesAccepted = 'cookiesAccepted',
  abundanceMode = 'abundanceMode',
  teamBrowser = 'teamBrowser',
  token = 'RESISTOMAP/TOKEN',
  organizationCreated = 'organizationCreated',
  overviewTutorialStep = 'overviewTutorialStep',
  mobileNoticeShown = 'mobileNoticeShown',
  adminMode = 'adminMode',
  chartDisplayMode = 'chartDisplayMode',
  notifiedAssayVersion = 'notifiedAssayVersion',
}

export function storedToken() {
  const token = localStorage.getItem(LocalStorageId.token);
  return token?.replace(/^"(.*)"$/, '$1'); // use-local-storage-state jsonfies strings, while old versions of UI didn't
}

export function storedTeamBrowser() {
  return localStorage.getItem(LocalStorageId.teamBrowser) === 'true';
}

export function storedCookiesAccepted() {
  return localStorage.getItem(LocalStorageId.cookiesAccepted) === 'true';
}
